import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal
} from "reactstrap"
import { Group, OrderForm, OrderPost } from '../../models/Order'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { RootState } from 'setup'
import { postWithoutTokenFunciton } from "utils/ApiHelper"

type Props = {

}
export type MultipleEditModalHandle = {
    showModal: (orders: number[], priorityOnly: boolean, onCompleted: (data: any) => void) => void;
};


const MultipleEditModal = forwardRef<MultipleEditModalHandle, Props>((props, ref) => {

    const groups: Group[] = useSelector<RootState>(({ orders }) => orders.groups, shallowEqual) as Group[] || []
    const [max_tab, setMax_tab] = useState("")
    const [priority, setPriority] = useState(0)
    const [enabled, setEnabled] = useState(1)
    const [group, setGroup] = useState(0)
    const [note, setNote] = useState("")
    const [sub_need, setSub_need] = useState("")
    const [show, setVisible] = useState(false);
    const [orders, setOrders] = useState<number[]>([]);
    const [priorityOnly, setPriorityOnly] = useState<boolean>(false);


    const [callback, setCallBack] = useState<(data: any) => void>();

    useImperativeHandle(ref, () => ({
        showModal
    }));

    const dismissModal = () => {
        setOrders([]);
        setSub_need('')
        setNote('')
        setMax_tab('')
        setVisible(false);
    }
    const updateOrder = async () => {
        try {
            if (!priorityOnly && (!parseInt(max_tab) && !note && !parseInt(sub_need))) {
                alert("Vui lòng điền số luồng và số lượng cần chạy");
                return;
            }


            if (priorityOnly) {
                const params={
                    orders:orders,
                    priority:priority
                }
                let res:any = await postWithoutTokenFunciton(`order/updateMulti`, params);
           
                if(res.data.data.orders){
                    callback && callback({
                        orders:res.data.data.orders,
                        priority:priority
                    });
                }else{
                    alert(res?.data?.error)
                }
                dismissModal();

            } else {
                let params:any= {
                    priority:priority,
                    orders:orders,
                    note:note,
                }
                if(sub_need!=''){
                    params['sub_need']=parseInt(sub_need);
                }
                if(max_tab!=''){
                    params['max_tab']=parseInt(max_tab);
                }

                let res = await postWithoutTokenFunciton(`order/updateMulti`, params);
                
                if(res?.data.data.orders){
                    callback && callback({
                        orders:res.data.data.orders,
                        priority:priority,
                        note:note,
                        sub_need:parseInt(sub_need) ,
                        max_thread:parseInt(max_tab)
                    });
                    dismissModal();
                }
              
              
                console.log('updateOrder', res);
            }


        } catch (e: any) {
            alert(`Update failed ${e.message}`);
        }


    }

    const showModal = (orders: number[], priorityOnly: boolean, onCompleted: (data: any) => void) => {
        setPriorityOnly(priorityOnly)
        setOrders(orders);
        setVisible(true);
        setCallBack(() => onCompleted);
    }

    return (
        <Modal isOpen={show}
            modalTransition={{ timeout: 500 }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Thông tin người dùng </h5>
                    {/* <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close">
                        <span className="svg-icon svg-icon-2x"></span>
                    </div> */}
                </div>
                <div className="modal-body">
                    <p>Chỉnh sửa thông tin người dùng {orders.join(", ")}</p>
                    <Form>
                        {
                            !priorityOnly && <FormGroup>
                                <Label for="exampleEmail" className="required form-label">
                                    Lượng theo dõi cần chạy (Tối thiểu 50 và chia hết cho 50)
                                </Label>
                                <Input
                                    id="sub_need"
                                    name="sub_need"
                                    value={sub_need}
                                    className="form-control form-control-solid"
                                    placeholder="ví dụ : 1000"
                                    onChange={(e) => setSub_need(e.target.value)}
                                    type="number"
                                />
                            </FormGroup>
                        }
                        {
                            !priorityOnly &&
                            <FormGroup>
                                <Label for="exampleEmail" className="required form-label">
                                    Số luồng max
                                </Label>
                                <Input
                                    id="max_tab"
                                    name="max_tab"
                                    value={max_tab}

                                    onChange={(e) => setMax_tab(e.target.value)}
                                    className="form-control form-control-solid"
                                    placeholder="ví dụ : 1000"
                                    type="number"
                                />
                            </FormGroup>
                        }
                        {
                            !priorityOnly &&
                            <FormGroup>
                                <Label for="exampleEmail" className="required form-label">
                                    Ghi chú
                                </Label>
                                <Input
                                    id="list_id"
                                    name="list_id"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    className="form-control form-control-solid"
                                    type={"textarea"}
                                    style={{ height: "100px" }}
                                />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Ưu tiên
                            </Label>
                            <Input
                                onChange={(e) => setPriority(parseInt(e.target.value))}
                                className="form-control form-control-solid"
                                type="select"
                                value={priority}
                            >
                                <option value={"0"}>
                                    Bình thường
                                </option>
                                <option value={"1"}>
                                    Ưu tiên
                                </option>
                            </Input>
                        </FormGroup>
                        {
                            /*
                            <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Trạng thái
                            </Label>
                            <Input
                                onChange={(e) => setEnabled(parseInt(e.target.value))}
                                className="form-control form-control-solid"
                                type="select"
                                value={enabled}
                            >
                                <option value={"0"}>
                                    Ngừng
                                </option>
                                <option value={"1"}>
                                    Chạy
                                </option>
                            </Input>
                           </FormGroup>
                            */
                        }
                    </Form>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={dismissModal} className="btn btn-light" >Thoát</button>
                    <button type="button" onClick={updateOrder} className="btn btn-primary">Lưu thông tin mới</button>
                </div>
            </div>
        </Modal>
    )
});

export default MultipleEditModal