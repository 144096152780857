import './app.scss'
import '../../../assets/scss/style.scss'

import * as actionTypes from 'setup/redux/DattaThemeOptions'

import React, {Children, Component, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import Breadcrumb from './Breadcrumb'
import {Fullscreen} from 'react-full-screen'
import Loader from '../Loader'
import NavBar from './NavBar'
import Navigation from './Navigation'
import {connect} from 'react-redux'
import windowSize from 'react-window-size'

class AdminLayout extends Component {
  fullScreenExitHandler = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.props.onFullScreenExit()
    }
  }

  componentWillMount() {
    if (
      this.props.windowWidth > 992 &&
      this.props.windowWidth <= 1024 &&
      this.props.layout !== 'horizontal'
    ) {
      this.props.onComponentWillMount()
    }
  }

  mobileOutClickHandler() {
    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      this.props.onComponentWillMount()
    }
  }

  render() {
    /* full screen exit call */
    document.addEventListener('fullscreenchange', this.fullScreenExitHandler)
    document.addEventListener('webkitfullscreenchange', this.fullScreenExitHandler)
    document.addEventListener('mozfullscreenchange', this.fullScreenExitHandler)
    document.addEventListener('MSFullscreenChange', this.fullScreenExitHandler)

    return (
      <>
        <Navigation />
        <NavBar />
        <div className='pcoded-main-container' onClick={() => this.mobileOutClickHandler}>
          <div className='pcoded-wrapper'>
            <div className='pcoded-content'>
              <div className='pcoded-inner-content'>
                <Breadcrumb />
                <div className='main-body'>
                  <div className='page-wrapper'>{this.props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    defaultPath: state.DattaThemeOptions.defaultPath,
    isFullScreen: state.DattaThemeOptions.isFullScreen,
    collapseMenu: state.DattaThemeOptions.collapseMenu,
    configBlock: state.DattaThemeOptions.configBlock,
    layout: state.DattaThemeOptions.layout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFullScreenExit: () => dispatch({type: actionTypes.FULL_SCREEN_EXIT}),
    onComponentWillMount: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(AdminLayout))
