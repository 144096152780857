import {setSidebarToggle, setSidebarToggleMobile} from '../../../../setup/redux/ThemeOptions'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink} from 'react-router-dom'
import React from 'react'
import {UncontrolledTooltip} from 'reactstrap'
import clsx from 'clsx'
import {connect} from 'react-redux'
import projectLogo from '../../../assets/images/react.svg'

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = props

  return (
    <>
      <div className='app-sidebar--header'>
        <div className='app-sidebar-logo'>
          <NavLink
            to='/'
            title='Bamburgh React Admin Dashboard with Reactstrap PRO'
            className='app-sidebar-logo'
          >
            <div className='app-sidebar-logo--icon'>
              <img alt='Bamburgh React Admin Dashboard with Reactstrap PRO' src={projectLogo} />
            </div>
            <div className='app-sidebar-logo--text'>
              <span></span>
              <b>MKBooster</b>
            </div>
          </NavLink>
        </div>
        <button
          onClick={toggleSidebar}
          className='btn btn-sm collapse-sidebar-btn'
          id='CollapseSidebarTooltip'
        >
          <FontAwesomeIcon icon={['far', 'dot-circle']} size='lg' />
        </button>
        <UncontrolledTooltip
          target='CollapseSidebarTooltip'
          container='.app-sidebar'
          placement='right'
        >
          Collapse sidebar
        </UncontrolledTooltip>
        <button
          className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
            'is-active': sidebarToggleMobile,
          })}
          onClick={toggleSidebarMobile}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner' />
          </span>
        </button>
        <button
          onClick={toggleSidebar}
          className='expand-sidebar-btn btn btn-sm'
          id='ExpandSidebarTooltip'
        >
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip
          target='ExpandSidebarTooltip'
          container='.app-sidebar'
          placement='right'
        >
          Expand sidebar
        </UncontrolledTooltip>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader)
