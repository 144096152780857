import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap"
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Group } from '../../models/Order'
import { OrderModel } from 'app/modules/orders/models/Order'
import { RootState } from 'setup'
import { actions } from '../../redux/OrdersRedux'

type Props = {
    item: OrderModel
}
const EditModal: React.FC<Props> = ({ item }) => {
    const dispatch = useDispatch()
    const groups: Group[] = useSelector<RootState>(({ orders }) => orders.groups, shallowEqual) as Group[] || []
    const [max_tab, setMax_tab] = useState("" + item.max_thread)
    const [priority, setPriority] = useState("" + item.priority)
    const [enabled, setEnabled] = useState("" + item.enabled)
    const [group, setGroup] = useState(item.group_id)
    const [note, setNote] = useState(item.note)
    const [sub_need, setSub_need] = useState("" + (item.sub_need ))

    const dismissModal = () => {
        dispatch(actions.clearcurrentOrder())
    }
    const updateOrder = () => {
        dispatch(actions.requestUpdate({
            max_thread: parseInt(max_tab),
            priority: parseInt(priority),
            group_id: group,
            note,
            sub_need : parseInt(sub_need),
            like_need: parseInt(sub_need),
            channel_id: item.channel_id ?? "",
            video_id: item.video_id ?? "",
            enabled: parseInt(enabled),
        }))
    }



    return (
        <Modal isOpen={true}
            modalTransition={{ timeout: 500 }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Thông tin người dùng {item?.channel_id}</h5>
                    {/* <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close">
                        <span className="svg-icon svg-icon-2x"></span>
                    </div> */}
                </div>
                <div className="modal-body">
                    <p>Chỉnh sửa thông tin người dùng</p>
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Lượng theo dõi cần chạy (Tối thiểu 50 và chia hết cho 50)
                            </Label>
                            <Input
                                id="sub_need"
                                name="sub_need"
                                value={sub_need}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setSub_need(e.target.value)}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Số luồng max
                            </Label>
                            <Input
                                id="max_tab"
                                name="max_tab"
                                value={max_tab}

                                onChange={(e) => setMax_tab(e.target.value)}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Ghi chú
                            </Label>
                            <Input
                                id="list_id"
                                name="list_id"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                className="form-control form-control-solid"
                                type={"textarea"}
                                style={{ height: "100px" }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Nhóm
                            </Label>
                            <Input
                                onChange={(e) => setGroup(parseInt(e.target.value))}
                                className="form-control form-control-solid"
                                type="select"
                                value={group}
                            >
                                <option key={-1} value={-1}>
                                    {"Không có nhóm"}
                                </option>
                                {
                                    groups.map((item: Group) => {
                                        return <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Ưu tiên
                            </Label>
                            <Input
                                onChange={(e) => setPriority(e.target.value)}
                                className="form-control form-control-solid"
                                type="select"
                                value={priority}
                            >
                                <option value={"0"}>
                                    Bình thường
                                </option>
                                <option value={"1"}>
                                    Ưu tiên
                                </option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Trạng thái
                            </Label>
                            <Input
                                onChange={(e) => setEnabled(e.target.value)}
                                className="form-control form-control-solid"
                                type="select"
                                value={enabled}
                            >
                                <option value={"0"}>
                                    Ngừng
                                </option>
                                <option value={"1"}>
                                    Chạy
                                </option>
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={dismissModal} className="btn btn-light" >Thoát</button>
                    <button type="button" onClick={updateOrder} className="btn btn-primary">Lưu thông tin mới</button>
                </div>
            </div>
        </Modal>
    )
}

export default EditModal