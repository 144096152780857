import './../../../assets/scss/style.scss';

import * as Yup from 'yup'
import * as auth from '../redux/AuthRedux'

import { Button, Card, Col, FormGroup, Input } from 'reactstrap'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { NavLink as NavLinkStrap } from 'reactstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import clsx from 'clsx'
import { login } from '../redux/AuthCRUD'
import people3 from '../../../assets/images/stock-photos/people-3.jpg'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then((res: any) => {
            
            setLoading(false)
            if (res.error) {
              setSubmitting(false)
              setStatus(res.error)
            } else {
              dispatch(auth.actions.login( res.data.data.token))
            }
          })
          .catch((e) => {
            console.log('login', e)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const onRecaptchaCompleted = () => {
    setCaptchaValidated(true)
  }

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className="">
          <div className="">
           
            <div className="card">
              <div className="card-body text-center">
                <h3 className="mb-4">{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}</h3>
                <p className='text-black-50'>
                  {intl.formatMessage({ id: 'AUTH.LOGIN.DESC' })}
                </p>
                <p>
                  {formik.status && (
                    <div className='px-4'>
                      <div className='mb-lg-15 alert alert-danger rounded-sm'>
                        <div className='alert-text'>{formik.status}</div>
                      </div>
                    </div>
                  )}
                </p>
                <div className="input-group mb-3">
                  <Input
                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  <div className="input-group mb-3">
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-group mb-4">
                  <Input
                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD.PLACEHOLDER' })}
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <div className="input-group mb-3">
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
                {
                  /*
                   <div className="input-group mb-4">
                    <ReCAPTCHA
                      sitekey="6LesMQwgAAAAAKrwaQt7l7Nh0OEJ8ghPbLTa_eG1"
                      onChange={onRecaptchaCompleted}
                    />
                  </div>
                  */
                }

                <Button
                  size='lg'
                  className='btn-block text-uppercase font-weight-bold font-size-sm'
                  color='primary'
                  disabled={
                    formik.isSubmitting || !formik.isValid
                    //|| !captchaValidated
                  }
                >
                  {intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}
                </Button>
                <p className='text-center text-black-50 mt-3'>
                  {intl.formatMessage({ id: 'AUTH.GENERAL.NO_ACCOUNT' })}&nbsp;
                  <Link to='/auth/registration' className='text-first link-primary fw-bolder'>
                    {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE' })}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
