import './styles.scss'
import axios from 'axios';

import {
  Card,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap'
import { Computer, Report } from 'app/modules/orders/models/Order'



/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { getReports, listComputer, exportCSV, updateStatus, listTasks, listStatisRp, listError, getSubStatis, getCountProfitp } from 'app/modules/orders/redux/OrdersCRUD'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Chart from 'react-apexcharts'
import { PageTitle } from '../../../_metronic/layout/core'
import { RootState } from 'setup'
import moment from 'moment'
import 'moment/locale/vi'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'

import { Modal } from 'react-bootstrap'

export const BASE_URL = process.env.REACT_APP_API_URL

interface ParentProps {
  lackThread: number;
}


const Statistic: FC<ParentProps> = ({lackThread}) => {
  // Modal Account Die
  const [show, setShow] = useState(false);
  const [showNotCall, setShowNotCall] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShowNotCall = () => setShowNotCall(true);
  const handleCloseNotCall = () => setShowNotCall(false);

  const intl = useIntl()
  const dispatch = useDispatch()
  const [reports, setReports] = useState<any[]>([])
  const [subStatis, setSubStatis] = useState<any>()
  const [statis, setStatis] = useState<any>()
  const [statisRp, setStatisRP] = useState<any>()
  const [error, setError] = useState<any>()
  const [countProfit, setCountProfit] = useState<any>()
  const [tasks, setTasks] = useState<any>()
  const [timeInter,setTimeInter] =  useState(60)
  
  const [computers, setComputers] = useState<Computer[]>([])

  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + -7)

  const [from, setFrom] = useState<Date>(defaultDate)
  const [to, setTo] = useState<Date>(new Date())
  const getComputerList = async () => {
    const response: any = await listComputer()
    if (response && response.data) {
      setComputers(response.data.data)
    }
  }

  const getError = async () => {
    const response: any = await listError()
    if (response && response.data) {
      setError(response.data.data)
    }
  }

  const getStatisRp = async () => {
    const response: any = await listStatisRp()
    if (response && response.data) {
      setStatisRP(response.data.data)
    }
  }

  const handleCountProfit = async () => {
    const response: any = await getCountProfitp()
    if (response && response.data) {
      setCountProfit(response.data.data)
    }
  }

  const handleExportCSV = async (computer: any) => {
    await axios.get(BASE_URL + 'export?computer=' + computer)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tasks.csv');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error(error);
      });
  }


  const submitFilter = async () => {
    const response: any = await getReports(
      moment(from).format('DD-MM-yyyy'),
      moment(to).format('DD-MM-yyyy'),
      'subscribe'
    )

    if (response && response.data.data) {
      setReports(response.data.data.report)
      setStatis(response.data.data)
    }
  }

  const submitSubStatis = async () => {
    const response: any = await getSubStatis(
      moment(from).format('DD-MM-yyyy'),
      moment(to).format('DD-MM-yyyy'),
      'subscribe'
    )
    if (response && response.data.data) {
      setSubStatis(response.data.data)
    }
  }

  const getTasks = async () => {
    const response: any = await listTasks()
    if (response && response.data) {
      // if(response.data.data[response.data.data.length-1].count < 120)
      // {
      //   playSos()
      // }
      setTasks(response.data.data)
    }
  }

  useEffect(() => {
    getComputerList()
    getTasks()
    const intervel= setInterval(()=>{
      console.log("=========reload========")
      getComputerList()
      getTasks()
    }, 60000);

    const intervelTime= setInterval(()=>{
      const a=timeInter-1
      setTimeInter(timeInter>0?a:60)
    }, 1000);



    return () => {
      clearInterval(intervel)
      clearInterval(intervelTime)
      
    }
  }, [])


  useEffect(() => {
    getStatisRp()
  }, [])

  useEffect(() => {
    handleCountProfit()
  }, [])

  useEffect(() => {
    getComputerList()
  }, [])

  useEffect(() => {
    getError()
  }, [])

  useEffect(() => {
    submitFilter()
  }, [to, from])

  useEffect(() => {
    submitSubStatis()
  }, [to, from])

  const totalSubscription = useMemo(
    () => reports?.reduce((total_run, report) => total_run + parseInt(report.total_run), 0),
    [reports]
  )

  console.log("===total====" + totalSubscription)
  const totalThread = computers?.reduce((total, comp) => total + comp.run, 0) || 0
  computers?.sort((a: any, b: any) => {
    return b.computer_name - a.computer_name
  });

  const optionPerformance = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: '#21d4fd',
      width: 2,
      dashArray: 0,
    },
    title: {
      text: 'Avg subscribe',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: statis?.avg_performance?.map((rp: any) => rp.date),
    },
    markers: {
      size: 6,
      colors: '#21d4fd',
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
  }

  const optionOrder = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Sub and point order everyday',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories:  subStatis?.map((sub:any) => sub.date)
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
  const chartDashboardMonitoring3ADataAvgPerformance = [
    {
      name: 'Update success',
      data: statis?.avg_performance?.map((rp: any) => rp.performance),
    }
  ]
  const chartDashboardMonitoring3ADataOrder = [
    {
      name: "Sub order",
      data: subStatis?.map((sub:any) => sub.subOrder)
    },
    {
      name: "Point order",
      data: subStatis?.map((point:any) => point.totalPoint*-1)
    }
  ]

  const optionTasks = {
    chart: {
      height: 350,
      type: 'line',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: '#21d4fd',
      width: 2,
      dashArray: 0,
    },
    title: {
      text: 'Task',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: tasks?.map((rp: any) => rp.note_date),
      labels: {
        show: false,
      }
    },
    tooltip: {
      x: {
        show: true,
      }
    }
  }


  const chartDashboardMonitoring3ADataRequestTask = [
    {
      name: 'Update success',
      data: tasks?.map((rp: any) => rp.count),
    }
  ]
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''




  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Thống kê'}</PageTitle>
      {role === 'admin' ? (
        <>

          <div className='row'>
            <div className='col-lg-12 position-relative z-index-2'>
              <Card className='card-box mb-5 text-center'>
                <div className='card-box__bottom'>
                  <Chart options={optionOrder} series={chartDashboardMonitoring3ADataOrder} type='line' height={350} />
                </div>
              </Card>
              
            </div>
            <div className='col-lg-12 position-relative z-index-2'>
              <Card className='card-box mb-5 text-center'>
                <div className='card-box__bottom'>
                  <Chart options={optionPerformance} series={chartDashboardMonitoring3ADataAvgPerformance} type='line' height={205} />
                </div>
              </Card>
              
            </div>
            <div className="col-lg-12">
                <Card className='card-box mb-3 px-4 pt-3 pb-3'>
                  <div className='card-box__bottom'>
                    <Chart options={optionTasks} series={chartDashboardMonitoring3ADataRequestTask} type='line' height={205} />
                  </div>
                </Card>
              </div>
            <div className='col-lg-12 position-relative z-index-2'>
              <div className="row ">
                <div className="col-lg-4 col-md-6 col-12 cursor-pointer" onClick={handleShowNotCall}>
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-compass  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Mail chưa được gọi"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(statisRp?.total_not_call, 0, 3)} mail
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Tổng Mail bị lỗi"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(statisRp?.total_account_error, 0, 3)} mail
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 cursor-pointer" onClick={handleShow} >
                  <div className="card bg-gradient-secondary ">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Mail bị die trong 1 ngày"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {
                            statisRp?.list_account_die.reduce((total: any, item: any, i: any) => {
                              return total + item.total
                            }, 1)
                          }  mail
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Mail hoạt động trong 24h"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(statisRp?.total_live, 0, 3)} mail
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Tỉ lệ Sub trung bình"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {statisRp?.average_performance} %
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Quest lỗi / tổng quest"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {error?.error_count} / {error?.total_count}
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Tổng point hôm nay"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(Math.abs(countProfit?.total_point_today), 0, 3)}
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Tổng sub hôm nay"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(Math.abs(countProfit?.total_sub), 0, 3)}
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 " >
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-triangle  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Tổng order hôm nay"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(Math.abs(countProfit?.count_order), 0, 3)}
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <Modal show={show} className='modal-account-die'>
                  <Modal.Header closeButton>
                    <Modal.Title>{"Mail bị die trong 1 ngày"}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th className='text-center'>ID</th>
                            <th>Máy</th>
                            <th>Số lượng</th>
                            <th>Xuất file CSV</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            statisRp?.list_account_die.map((item: any, i: any) => {
                              return (
                                <tr>
                                  <td className='text-center'>{i + 1}</td>
                                  <td>{item.computer}</td>
                                  <td>{item.total}</td>
                                  <td><button onClick={() => handleExportCSV(item.computer)} className='btn btn-sm btn-primary px-5'>Tải về</button></td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={handleClose} className='btn btn-primary px-5'>Close</button>
                  </Modal.Footer>
                </Modal>

                <Modal show={showNotCall} className='modal-account-die'>
                  <Modal.Header closeButton>
                    <Modal.Title>{"Mail chưa được gọi"}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th className='text-center'>ID</th>
                            <th>Computer</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            statisRp?.list_not_call.map((item: any, i: any) => {
                              return (
                                <tr>
                                  <td className='text-center'>{i + 1}</td>
                                  <td><b>{item.computer}</b></td>
                                  <td>{item.total}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={handleCloseNotCall} className='btn btn-primary px-5'>Close</button>
                  </Modal.Footer>
                </Modal>

              </div>
            </div>          
          </div>
        </>
      ) : null}
    </>
  )
}

export { Statistic }
