import './styles.scss'
import axios from 'axios';

import {
  Card,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap'
import { Modal } from 'react-bootstrap'
import { Computer, Report } from 'app/modules/orders/models/Order'



/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { getReports, listComputer, exportCSV, updateStatus, listTasks, deleteComputer, fillTask, updateInfoComputer } from 'app/modules/orders/redux/OrdersCRUD'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Chart from 'react-apexcharts'

import LoadingRow from 'app/modules/orders/components/LoadingRow'
import { RootState } from 'setup'
import moment from 'moment'
import 'moment/locale/vi'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'
import { Affix, Button } from 'antd';
import Swal from 'sweetalert2'


export const BASE_URL = process.env.REACT_APP_API_URL

interface ChildProps {
  lackThread: number;
}

const ListCom: FC<ChildProps> = ({lackThread}) => {
  const intl = useIntl()
  const [statisRp, setStatisRP] = useState<any>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [action, setAction] = useState('')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const hasSelected = selectedRowKeys.length > 0
  const [timeInter,setTimeInter] =  useState(60)
  const [loadFillTask,setLoadFillTask] =  useState(false)
  const [loadRefresh,setLoadRefresh] =  useState(false)
  const [isShowUpdateInfo,setIsShowUpdateInfo] =  useState(false)
  const [limit_day,setLimit_day] =  useState(0)
  const [thread,setThread] =  useState(0)

  const audioPlayer = useRef<HTMLAudioElement | null>(null);
  function playSos() {
    if (audioPlayer && audioPlayer.current) {
      audioPlayer.current.play();
    }
  }

  const handleRefresh = async () => {
    setLoadFillTask(true)
    const response: any = await fillTask()
    if (response && response.data) {
      if (response.data.success === true) {
        Swal.fire({
          type: 'success',
          title: 'Cập nhật thành công'
        })
      }
      else {
        Swal.fire({
          type: 'error',
          title: response.data.error
        })
      }
    }
    setLoadFillTask(false)
  }

  const handleFillTask = async () => {
    setLoadRefresh(true)
      getComputerList()
      Swal.fire({
        type: 'success',
        title: 'Cập nhật thành công'
      })
    setLoadRefresh(false)
  }


  const handleUpdateStatus = async () => {
    if(action === 'reset')
    {
      const data = {
        action,
        computer_name: selectedRowKeys
      }
      const response: any = await updateStatus(data)
      if (response && response.data) {
        if (response.data.success === true) {
          Swal.fire({
            type: 'success',
            title: 'Cập nhật thành công'
          })
        }
        else {
          Swal.fire({
            type: 'error',
            title: response.data.error
          })
        }
        setSelectedRowKeys([])
        getComputerList()
      }
    }
    else if(action === 'updateInfo')
    {
      setIsShowUpdateInfo(true)
    }
  }

  const dismissModal = () => {
    setIsShowUpdateInfo(false)
  }

  const handleUpdateInfoComputer = async () =>
  {
    const data = {
      computer_name : selectedRowKeys,
      thread,
      limit_day
    }

    const response: any = await updateInfoComputer(data)
      if (response && response.data) {
        if (response.data.success === true) {
          Swal.fire({
            type: 'success',
            title: 'Cập nhật thành công'
          })
          setIsShowUpdateInfo(false)
        }
        else {
          Swal.fire({
            type: 'error',
            title: response.data.error
          })
        }
        getComputerList()
      }
  }

  const onDeleteComputer = async (computer_name:any) => {
    const data = {
      computer_name
    }
    if (window.confirm('Xóa computer này?') === true) {
      const response: any = await deleteComputer(data)
      if (response && response.data) {
        if (response.data.success === true) {
          Swal.fire({
            type: 'success',
            title: 'Xóa thành công'
          })
        }
        else {
          Swal.fire({
            type: 'error',
            title: response.data.error
          })
        }
        getComputerList()
      }
    }
  }



  const onSelectChange = (e: any) => {
    if (!e.target.checked) {

      setSelectedRowKeys((current: any) =>
        current.filter((computer: any) => computer !== e.target.value)
      )
    }
    else {
      const data = [
        ...selectedRowKeys,
        e.target.value
      ];

      setSelectedRowKeys(data)
    }
  }

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedRowKeys(computers.map(li => li.computer_name));
    if (isCheckAll) {
      setSelectedRowKeys([]);
    }
  };

  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''


  const computersLoading: boolean =
    (useSelector<RootState>(({ orders }) => orders.computersLoading, shallowEqual) as boolean) ||
    false
  const [computers, setComputers] = useState<Computer[]>([])

  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + -7)

  const getComputerList = async () => {
    const response: any = await listComputer()
    if (response && response.data) {
      setComputers(response.data.data)
    }
  }

  

  useEffect(() => {
    getComputerList()
    const intervel= setInterval(()=>{
      console.log("=========reload========")
      getComputerList()
    }, 60000);

    const intervelTime= setInterval(()=>{
      const a=timeInter-1
      setTimeInter(timeInter>0?a:60)
    }, 1000);



    return () => {
      clearInterval(intervel)
      clearInterval(intervelTime)
      
    }
  }, [])




  const totalThread = computers?.reduce((total, comp) => total + comp.run, 0) || 0
  computers?.sort((a: any, b: any) => {
    return b.computer_name - a.computer_name
  });

  

  return (
    <div className='row'>
    <audio ref={audioPlayer} src='media/sos.mp3' />
      <div className="col-lg-12">
        <Card className='card-box mb-3 px-4 pt-3 pb-3'>
          <div className='card-header-alt'>
            <h2 className='text-center font-weight-bold font-size-lg mb-2 text-black uppercase'>
              {"Tiến trình hệ thống "+timeInter}
            </h2>
          </div>
          <div className='list-server mt-3 row'>
            <div className='col-lg-6 col-md-6 col-12'>
              <div className='list-server__item text-center pt-4 pb-4'>
                <div className='line-height-sm'>
                  <b className='d-block'>{format(computers?.filter(com => com.run > 0).length, 0, 3)} </b>
                  <span className='badge badge-primary'>
                    {intl.formatMessage({ id: 'DASHBOARD.COMPUTER' })}
                  </span><br></br>
                  <Button type="primary" size={'small'} loading={loadFillTask} onClick={handleFillTask} className='mt-2 rounded'>
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-12 '>
              <div className='list-server__item text-center pt-4 pb-4'>
                <div className='line-height-sm'>
                  <b className='d-block'>
                    <span>{format(totalThread, 0, 3)} / {format(lackThread, 0 ,3)}</span>
                  </b>
                  <span className='badge badge-primary text-white'>
                    {intl.formatMessage({ id: 'DASHBOARD.THREAD' })}
                  </span><br></br>
                  <Button type="primary" size={'small'} loading={loadRefresh} onClick={handleRefresh} className='mt-2 rounded'>
                    Fill Task
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Affix offsetTop={10}>
          <div className='c-order__nav row'>
            <div className="col-md-2 col-sm-4 d-flex align-items-center justify-content-beween">
              <input type="checkbox" onClick={handleSelectAll} checked={isCheckAll} className='ml-3 mr-3' id='checkallcomputer' />
              <label htmlFor="checkallcomputer" className='mb-0'>Check All</label>
            </div>
            <div className="col-md-6 col-sm-8 d-flex align-items-center c-order__nav__mid">
              <select className="form-select flex-fill" aria-label="Default select example" onChange={(e) => setAction(e.target.value)} value={action}>
                <option value="" selected>Chọn action</option>
                <option value="reset">Reset tool</option>
                <option value="updateInfo">Update thông số</option>
              </select>
              {hasSelected ? <span className='flex-fill text-center' style={{ margin: '0px 8px' }}>Đã chọn {selectedRowKeys.length}</span> : ''}
              <button
                disabled={!hasSelected}
                onClick={handleUpdateStatus}
                className='btn btn-sm btn-warning'
              >
                Update
              </button>
            </div>
          </div>
        </Affix>
        <Row className='mb-2 row-server'>
          {computersLoading ? (
            <LoadingRow numberRow={5} />
          ) : (
            computers &&
            computers.map((item: Computer, index: number) => {
              const color = item.run >= 15 ? 'green' : item.run < 15 && item.run > 5 ? 'yellow' : 'red'
              return (
                <Col className='box-col-3 des-xl-25 rate-sec col-sm-4 col-md-2 position-relative'>
                  <i className="bi bi-x-square-fill fa-2x position-absolute mx-2 mt-1 cursor-pointer" onClick={() => onDeleteComputer(item.computer_name)} style={{zIndex:999}}></i>
                  <a href={item.link_computer} target='_blank'><i className="bi bi-arrow-bar-right fa-2x position-absolute mx-4 mt-1 cursor-pointer" style={{zIndex:999, top:0, right:0}}></i></a> 
                  <div className='card income-card'>
                    <div className={'text-center card-body computer-off-' + item.run}>
                      <h5>{item.computer_name}</h5>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                        <span className='text-white'>{item.limit_day}</span>
                      </span>
                      <p className='income-card__number'>

                        {intl.formatMessage({ id: 'THREAD_NUMBER' })}: <span className={'income-card__name income-card__name--' + color}>{item.run} / {item.thread}</span>
                      </p>
                      <span className='badge badge-primary mb-2'>
                        {moment(item.time).format('DD/MM hh:mm a')}
                      </span><br></br>
                      <input type="checkbox" value={item.computer_name} onClick={(e) => onSelectChange(e)} checked={selectedRowKeys.includes(item.computer_name)} /><br></br>
                      <span className='badge badge-primary my-2'>
                        Action : {moment.unix(item.last_action_time).fromNow()} {item.action}
                      </span>
                      <p>Mail sống: {item.account_live}</p>
                      <p>Mail hoạt động: {item.account_work}</p>
                      <p>Mail chết: {item.account_die}</p>
                      <span className='badge badge-primary mb-2'>
                        Thời gian reset : {item.reset_time}
                      </span>
                    </div>
                  </div>
                </Col>
              )
            })
          )}
        </Row>
      </div>
      <Modal show={isShowUpdateInfo} >
        <div className="modal-content">
          <div className="modal-header">
              <h5 className="modal-title">Sửa thông tin máy</h5>
          </div>
          <div className="modal-body">
              <Form>
                  <FormGroup>
                      <Label for="exampleEmail" className="required form-label">
                          Số luồng
                      </Label>
                      <Input
                          id="thread"
                          name="thread"
                          value={thread}
                          className="form-control form-control-solid"
                          placeholder="Name..."
                          onChange={(e) => setThread(parseInt(e.target.value))}
                          type="text"
                      />
                  </FormGroup>
                  <FormGroup>
                      <Label for="exampleEmail" className="required form-label">
                          Max sub ngày
                      </Label>
                      <Input
                          id="limit_day"
                          name="limit_day"
                          value={limit_day}
                          className="form-control form-control-solid"
                          placeholder="Name..."
                          onChange={(e) => setLimit_day(parseInt(e.target.value))}
                          type="text"
                      />
                  </FormGroup>
              </Form>
          </div>
          <div className="modal-footer">
              <button type="button" onClick={dismissModal} className="btn btn-light" >Thoát</button>
              <button type="button" onClick={handleUpdateInfoComputer} className="btn btn-primary">Lưu thông tin mới</button>
          </div>
        </div>
      </Modal>

    </div>

    
  )
}

export default ListCom 
