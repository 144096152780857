/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
// import './_metronic/assets/sass/style.scss'
// import './_metronic/assets/sass/style.react.scss'
import 'antd/dist/antd.css'
// import './app/assets/antd.less'
import './app/assets/base.scss'

import * as _redux from './setup'

import { Chart, registerables } from 'chart.js'
import {
  faAddressCard,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faDotCircle,
  faFileArchive,
  faFileImage,
  faImages,
  faLifeRing,
  faQuestionCircle,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle,
  far,
} from '@fortawesome/free-regular-svg-icons'
import {
  faMoneyBillWave,
  faAlignCenter,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAltH,
  faBars,
  faBolt,
  faCameraRetro,
  faCheck,
  faDesktop,
  faExclamation,
  faInfoCircle,
  faPager,
  faPlus,
  faPrint,
  faQuoteRight,
  faSave,
  faShapes,
  faShareSquare,
  faSmile,
  faStarHalfAlt,
  faSync,
  faTable,
  faUpload,
  fas,
} from '@fortawesome/free-solid-svg-icons'
import store, { persistor } from './setup/redux/Store'

// Apps
// import {App} from './app/App'
import { App } from './app/App.MD2'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
// Axios
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faMoneyBillWave,
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faCalendarAlt,
  faAddressCard,
  faImages,
  faUser,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faChartBar,
  faDesktop,
  faBolt,
  faTrashAlt,
  faPlus
)
library.add(
  faMoneyBillWave,
  fas,
  faExclamation,
  faCheck,
  faSmile,
  faPrint,
  faAlignCenter,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faTable,
  faPager,
  faCameraRetro,
  faUpload,
  faBars,
  faSave,
  faArrowsAltH,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft
)
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Đang tải...</div>}>
        <App basename={''} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
