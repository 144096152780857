import axios from 'utils/AxiosConfig'
export const postWithoutTokenFunciton = async (url: string, data: any) => {
  try {
    const res = await axios
      .post(url, data)
      .then(function (response) {
        return {
          data: response.data,
        }
      })
      .catch(function (error) {
        console.log('fetch get function error data', error.response.data)
        if (error.response != null) {
          if (error.response.status === 401) {
            return {
              error: 'token exprired',
            }
          }
          if (error.response.data.data && error.response.data.data.error) {
            return {
              data: {
                error: error.response.data.data.error
              },
            }
          }
          return {
            error: 'There is an exception error !',
          }
        }
        return {
          error: 'There is an exception error !',
        }
      })
    return res
  } catch (er) {}
}

export const getFunciton = async (url: string) => {
  try {
    //const accessToken="";
    const res = await axios
      .get(url)
      .then(function (response) {
        //console.log("====response====",response)
        return {
          data: response.data,
        }
      })
      .catch(function (error) {
        if (error.response != null && error.response.status === 401) {
          //console.log("token exprired")
          return {
            error: 'token exprired',
          }
        }
        return {
          data:error?.response?.data
        }
      })
    return res
  } catch (er) {}
}
