import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AdminLayout from 'app/layout/layout-datta-able/AdminLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Statistic } from '../pages/dashboard/Statistic'
import { FallbackView } from '../../_metronic/partials'
import { SettingWrapper } from '../pages/setting/SettingWrapper'
import { useLocation } from 'react-router-dom'
import { ServicesPage } from 'app/pages/setting/ServicesPage'

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const AccountDetailPage = lazy(() => import('../modules/accounts/AccountDetail'))
  const WidgetsPage = lazy(() => import('../modules/orders/OrdersPage'))
  const CreateOrderPage = lazy(() => import('../modules/orders/CreateOrder'))
  const OrderList = lazy(() => import('../modules/orders/Orders'))
  const location = useLocation()

  return (
    <AdminLayout>
      <Suspense fallback={<FallbackView />}>
        <Switch location={location} key={location.pathname}>
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/statistic' component={Statistic} />
          <Route path='/setting' component={SettingWrapper} />
          <Route path='/services' component={ServicesPage} />
          <Route path='/page/account' component={AccountPage} />
          <Route path='/page/account-detail/:user_id' component={AccountDetailPage} />
          <Route path='/page/subscribeOrders/create' component={(props: any) => <CreateOrderPage {...props} apiAlias="subscribe" />} />
          <Route path='/page/subscribeOrders/list' component={(props: any) => <OrderList {...props} apiAlias="subscribe" />} />
          <Route path='/page/subscribeOrders/:state' component={(props: any) => <WidgetsPage {...props} apiAlias="subscribe" />} />
          <Route path='/page/likeOrders/create' component={(props: any) => <CreateOrderPage {...props} apiAlias="like" />} />
          <Route path='/page/likeOrders/list' component={(props: any) => <OrderList {...props} apiAlias="like" />} />
          <Route path='/page/likeOrders/:state' component={(props: any) => <WidgetsPage {...props} apiAlias="like" />} />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </AdminLayout>
  )
}
