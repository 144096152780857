import './styles.scss'

import {
  Card,
  Col,
  Row
} from 'reactstrap'

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import {useDispatch } from 'react-redux'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { getMyPoints} from 'app/modules/orders/redux/OrdersCRUD'
import moment from 'moment'
import {Table} from 'antd'
import { format } from 'utils/convert'
const SubscribeStatisticWidget: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [points, setPoints] = useState([])


  const fetchSetting = async() => {
    const { data: result } = await getMyPoints()
    if(result&&result.data){
      const mapWithIndex = result.data.reverse().map((item:any,index:number)=>{
        return {
          ...item,
          index:index+1
        }
      })
      setPoints(mapWithIndex)
    }

  }

  useEffect( () => {
    fetchSetting()
  }, [])

  

  return (
    <Card className='card-box p-settingCard text-center'>
      <div className='card-header-alt'>
        <div className='font-weight-bold d-flex align-items-center justify-center p-settingCard__heading'>
          <i className='feather icon-settings mr-3'></i>
          <h3>{intl.formatMessage({ id: 'Lịch sử hoạt động' })}</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <Table
            dataSource={points}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: false,
            }}
            columns={[
              {
                title: '#',
                dataIndex: 'index',
                key: 'index',
              },
              {
                title: intl.formatMessage({id: 'POINT.DATETIME'}),
                dataIndex: 'datetime',
                key: 'datetime',
                render: (value: any, item: any, index: number) => {
                  return `${moment.unix(item.datetime).format("DD/MM HH:mm")}`
                },
              },
              {
                title: intl.formatMessage({id: 'POINT.POINT'}),
                dataIndex: 'point',
                key: 'point',
                render: (value: any, item: any, index: number) => {
                  return format(item.point, 0, 3);
                }
              },
              {
                title: intl.formatMessage({id: 'POINT.DESCRIPTION'}),
                dataIndex: 'description',
                key: 'description',
                render: (value: any, item: any, index: number) => {
                const string = item.description;
                    if(string.includes("Order Channel : ")) {
                      const result = string.replace('Order Channel : ','');
                      return <b><a href={'https://www.youtube.com/channel/' + result}>{item.description}</a></b>
                      
                    }
                    else if(string.includes("Order LikeQuest : ")) {
                      const result = string.replace('Order LikeQuest : ','');
                      return <b><a href={'https://www.youtube.com/watch?v=' + result}>{item.description}</a></b>
                    }
                    else {
                      return <b>{item.description}</b>
                    }
                  }
              },
             
              {
                dataIndex: 'balance',
                key: 'balance',
              }
            ]}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </Card>
  )
}



const MeWrapper: FC = () => {
  const intl = useIntl()


  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Thông tin người dùng'}</PageTitle>
        <Row>
          <Col lg='12'>
            <SubscribeStatisticWidget />
          </Col>
        </Row>
    </>
  )
}

export { MeWrapper }
