import { Divider, Form, Table, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { OrderModel } from './models/Order'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { AccountModel } from 'app/modules/accounts/models/Account'
import DatePicker from 'react-datepicker'
import { RootState } from 'setup'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'
import { getListAccount, getListRestore } from '../accounts/AccountCRUD';
import RestoreModal, { RestoreModalHandle } from './modals/RestoreModal';
import { postWithoutTokenFunciton } from 'utils/ApiHelper';
type Props = {
  className: string
  state: string
  apiAlias: string
}

const RestoreHistoryList: React.FC<Props> = ({ apiAlias, className, state }) => {
  const intl = useIntl()
  const restoreModalHandle = useRef<RestoreModalHandle>(null)
  const [arrRestore, setArrRestore] = useState<any[]>([])
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''
  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState<AccountModel[]>([])
  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + -7)
  const [fromRetore, setFromRetore] = useState<Date>(defaultDate)
  const [toRetore, setToRetore] = useState<Date>(new Date())
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const fetchAccountTask = async () => {
    const rest = await getListAccount()
    if (rest?.data?.data) {
      setAccounts(rest?.data.data)
    }
  }

  const showRestoreModal = () => {
    if (restoreModalHandle && restoreModalHandle.current) {
      restoreModalHandle.current.showModal((data) => {
        setArrRestore(data)
      })
    }
  }

  const autoRestoreLoad = async () => {
    setLoading(true)
    const rest = await getListRestore(moment(fromRetore).format('DD-MM-YYYY'), moment(toRetore).format('DD-MM-YYYY'))
    if (rest?.data?.data) {
      console.log("====data===", rest?.data?.data)
      setArrRestore(rest?.data?.data)
    }
    setLoading(false)
  }

  const goRestoreList = async () => {
    setLoading(true)
    const selectedKeys: number[] = []
      selectedRowKeys.forEach((value: React.Key) => {
        selectedKeys.push( parseInt(value.toString()))
      })
    let res = await postWithoutTokenFunciton(`order/restorebylist`, {
      channels: selectedRowKeys
    });

    if (res?.data.data) {
      let string = "";
      const arrSuccess:any[]=[];
      res?.data.data.forEach((element: any) => {
        string = string + JSON.stringify(element) + "\n";
        arrSuccess.push(parseInt(Object.keys(element)[0]));
      });
      const remap = arrRestore.filter(item=>{
        if(arrSuccess.includes(item.order_id)){
          return false
        }
        return true
      })
      setArrRestore(remap)
      //setResult(string)
    }
    setLoading(false)
  }

  const clickRestoreHandler = async (item: OrderModel) => {
    /*
    if (window.confirm('Yêu cầu bảo hành kênh') === true) {
      const res: any = await restoreChannel(item.order_id)
      console.log(res)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      } else {
        if (res?.data?.data?.error) {
          alert(res?.data?.data?.error)
        }

      }
    }
    */
  }

  useEffect(() => {
    fetchAccountTask()
  }, [])


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const hasSelected = selectedRowKeys.length > 0



  return (
    <div className='c-order'>
      <div className={`card ${className}`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar'>
            <div className='col'>
              <Form.Item label='Từ ngày' className='mb-0'>
                <DatePicker
                  dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                  className='form-control'
                  selected={fromRetore}
                  onChange={(date) => setFromRetore(date != null ? date : defaultDate)}
                  selectsStart
                  startDate={fromRetore}
                  endDate={toRetore}
                />
              </Form.Item>
            </div>
            <div className='col'>
              <Form.Item label='Đến ngày' className='mb-0'>
                <DatePicker
                  dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                  className='form-control'
                  selected={toRetore}
                  onChange={(date) => setToRetore(date != null ? date : new Date())}
                  selectsEnd
                  startDate={fromRetore}
                  endDate={toRetore}
                  minDate={fromRetore}
                />
              </Form.Item>
            </div>
            <div className='col'>
              <Button type="primary"
                disabled={loading}
                icon={<SearchOutlined />}
                onClick={autoRestoreLoad}>
                {
                  loading ? "Đang bảo hành" : "Quét bảo hành"
                }
              </Button>
            </div>
            <div className='col'>
              <Button type="primary"
                icon={<SearchOutlined />}
                onClick={showRestoreModal}>
                Bảo hành theo list
              </Button>
            </div>
          </div>
          <Divider />
          {
            hasSelected &&
            <Button type="primary"
              icon={<SearchOutlined />}
              onClick={goRestoreList}>
              Bảo hành danh sách đã chọn
            </Button>
          }
          <Divider />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive '>
            <Table
              loading={loading}
              bordered
              rowKey={(record) => record.order_id || 1}
              rowSelection={rowSelection}
              columns={[
                {
                  title: 'stt',
                  key: 'stt',
                  dataIndex: 'stt',
                  width: 50,
                  render: (value: any, record: any, index: any) => index + 1,
                },
                {
                  title: 'ID đơn hàng',
                  key: 'order_id',
                  dataIndex: 'order_id',
                  width: 150,
                  render: (value: any, item: any, index: any) => {
                    return (
                      <div className='text-center'>
                        <span
                          style={{ fontSize: '12px' }}
                          className=' fw-bold  d-block'
                        >
                          {item.order_id}
                        </span>
                      </div>
                    )
                  },
                },
                {
                  title: 'ID Chanel/User',
                  key: 'channel_id',
                  dataIndex: 'channel_id',
                  width: 150,
                  render: (value: any, item: any, index: any) => {
                    return (
                      <>
                        <a
                          href={'https://www.youtube.com/channel/' + item.channel_id}
                          className='text-dark fw-bolder text-hover-primary text-sm'
                        >
                          {item.channel_id}
                        </a>
                      </>
                    )
                  },
                },
                {
                  title: 'Hoàn thành',
                  key: 'end_date',
                  width: 100,
                  render: (value: any, item: any, index: any) => {
                    return (
                      <span
                        style={{ fontSize: '12px' }}
                        className=' fw-bold d-block'
                      >
                        {item?.end_time === undefined
                          ? ''
                          : moment.unix(item?.end_time).format('DD/MM HH:mm')}
                      </span>
                    )
                  },
                },
                {
                  title: `Bắt đầu`,
                  key: 'sub_start',
                  dataIndex: 'sub_start',
                  width: 100,
                  render: (value: any, item: any, index: any) => {
                    return `${format(item.sub_start || 0, 0, 3)}`
                  },
                },
                {
                  title: `Hiện tại`,
                  key: 'current_subs',
                  dataIndex: 'current_subs',
                  width: 100,
                  render: (value: any, item: any, index: any) => {
                    return `${format(parseInt(item.current_subs) || 0, 0, 3)}`
                  },
                },
                {
                  title: `Subs Order`,
                  key: 'sub_order_origin',
                  width: 100,
                  render: (value: any, record: any, index: any) => {
                    return `${format(record.sub_order_origin || 0, 0, 3)}`
                  },
                },
                {
                  title: `Subs Còn thiếu`,
                  key: 'remains',
                  width: 100,
                  render: (value: any, record: any, index: any) => {
                    return `${format(record.remains || 0, 0, 3)}`
                  },
                },
                {
                  title: `Subs cần chạy lại`,
                  key: 're_order',
                  width: 100,
                  render: (value: any, record: any, index: any) => {
                    return `${format(record.re_order || 0, 0, 3)}`
                  },
                },
                {
                  title: 'Bảo hành',
                  key: 'action_restore',
                  width: 150,
                  render: (value: any, record: any, index: any) => {
                    return (
                      <Button
                        color='neutral-primary'
                        onClick={() => clickRestoreHandler(record)}
                        className='mx-1 rounded-sm c-btnHistory'
                      >
                        Gửi yêu cầu
                      </Button>
                    )
                  },
                }
              ]}
              dataSource={arrRestore}
              pagination={false}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <RestoreModal ref={restoreModalHandle} />
    </div>
  )
}

export { RestoreHistoryList }
