import { Affix, Divider, Form, Input, Select, Space, Table, Tooltip , Modal, Button, Checkbox } from 'antd'
import { Badge, Col, Row } from 'reactstrap'
import { Group, OrderModel } from './models/Order'
import MultipleEditModal, { MultipleEditModalHandle } from './modals/EditModal/multiple'
import { OrderForm, OrderPost } from '../orders/models/Order'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { AccountModel } from 'app/modules/accounts/models/Account'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'setup'
import { actions } from './redux/OrdersRedux'
import { debounce } from 'lodash'
import moment from 'moment'
import { postWithoutTokenFunciton } from 'utils/ApiHelper'
import { useHistory } from 'react-router-dom'
import { format } from 'utils/convert'
import { getListAccount, getListServices } from '../accounts/AccountCRUD'
import { cancelChannel, completeChannel, confirmCancelChannel, getListOrder, getListVideo, getSubOfVideo, reloadVideo, cancelRefundPending } from './redux/OrdersCRUD'
type Props = {
  className: string
  state: string
  apiAlias: string
}

const OrderList: React.FC<Props> = ({ apiAlias, className, state }) => {
  const editMultipleModal = useRef<MultipleEditModalHandle>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState('')
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''
  const groups: Group[] =
    (useSelector<RootState>(({ orders }) => orders.groups, shallowEqual) as Group[]) || []
  const [currentGroup, setCurrentGroup] = useState<Group>()
  const [order_by, setOrder_by] = useState<number>(0)
  const [orders, setOrders] = useState<OrderModel[]>([])
  const [currentUser, setCurrentUser] = useState<AccountModel>()
  const [currentService, setCurrentService] = useState<OrderModel>()
  const [valueService, setValueService] = useState(0)
  const [priority, setPriority] = useState<number>(-1)
  const [currentChannelID, setCurrentChannelID] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [running, setRuning] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accounts, setAccounts] = useState<AccountModel[]>([])
  const [currentCancelChannel, setCurrentCancelChannel] = useState(-1)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSubOpen, setIsModalSubOpen] = useState(false);
  const [dataVideo, setDataVideo] = useState<any[]>([])
  const [dataSubOfVideo, setDataSubOfVideo] = useState<any[]>([])
  const [services, setServices] = useState<any[]>([])
  const [currentCancelVideo, setCurrentCancelVideo] = useState(-1)
  const [reason, setReason] = useState<string>('')
  const [isShowCancelAndRefund, setIsShowCancelAndRefund] = useState(false);
  const [isBlacklist, setIsBlacklist] = useState(false);
  const [reasonBl, setReasonBl] = useState('');
  
  const onChangeAddToBl = () => {
    setIsBlacklist(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalSub = () => {
    setIsModalSubOpen(true);
  };

  const handleCancelSub = () => {
    setIsModalSubOpen(false);
  };


  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: 'Video ID',
      dataIndex: 'video_id',
      key: 'video_id',
    },
    {
      title: 'Click',
      dataIndex: 'click',
      key: 'click',
    },
    {
      title: 'Load',
      dataIndex: 'load',
      key: 'load',
    }
  ];

  const columnsSub = [
    {
      title: 'Số sub',
      dataIndex: 'runed',
      key: 'runed',
    },
    
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  const getServiceTask = async () => {
    const rest = await getListServices()

    if (rest?.data?.data) {
      setServices(rest?.data.data)
    }
  }

  const fetchAccountTask = async () => {
    const rest = await getListAccount()
    if (rest?.data?.data) {
      setAccounts(rest?.data.data)
    }
  }
  const fetchOrderTask = async () => {
    setLoading(true)
    const rest = await getListOrder(state)
    if (rest?.data?.data) {
      setOrders(rest?.data.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchOrderTask()
    fetchAccountTask()
    getServiceTask()
  }, [])
  useEffect(() => {
    if (currentCancelChannel !== -1) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [currentCancelChannel, loading])

  useEffect(() => {
    if (!loading) {
      setCurrentCancelChannel(-1);
    }

  }, [loading])



  const clickUpdateHandler = (item: OrderModel) => {
    dispatch(actions.showcurrentOrder(item))
  }
  const clickShowListVideo = async (item: any) => {
    const res: any = await getListVideo(item.channel_id)
    
    if (res?.data?.success === true) {
      showModal()
      setDataVideo(res.data.data)
    }
  }

  const clickShowSubEveryday = async (item: any) => {
    const params = {
      order_id: item.order_id
    }
    const res: any = await getSubOfVideo(params)
    
    if (res?.data?.success === true) {
      showModalSub()
      setDataSubOfVideo(res.data.data)
    }
  }

  
  const clickCompleteHandler = async (item: OrderModel) => {
    if (window.confirm('bạn có chắc chắn muốn hoàn thành đơn này') === true) {
      const res: any = await completeChannel(item.order_id)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      }
    }
  }
  const clickReloadVideo = async (item: OrderModel) => {
    if (window.confirm('bạn có chắc chắn muốn reload video cho kênh này') === true) {
      const data = {
        channel_id : item.channel_id
      }
      const res: any = await reloadVideo(data)
      if (res?.data?.success === true) {
        alert('Reload thành công')
      }
    }
  }
  const clickCancelHandler = async (item: OrderModel) => {
    if (window.confirm('bạn có chắc chắn muốn hủy đơn này') === true) {
      const res: any = await cancelChannel(item.order_id)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      }
    }
  }

  const clickCancelAndRefund = (order_id: any) => {
    setIsShowCancelAndRefund(true)
    setCurrentCancelVideo(order_id)
  }

  const handleCancelRefund = async (item: Number, reason_cancel: String) => {
    const data = {
      order_id : item,
      reason: reason_cancel
    }
    if(reason === '' || reason === '0')
    {
      alert('Vui lòng chọn lý do!')
      return
    }
    const res: any = await cancelRefundPending(data)
    if (res?.data?.success === true) {
      setOrders(orders.filter(_item => {
        if (_item.order_id === item) {
          return false
        }
        return true
      }))
    }
    setIsShowCancelAndRefund(false)
    setCurrentCancelVideo(-1)
  }

  const clickConfirmCancelHandler = async (order_id: number, refund: boolean) => {
    const res: any = await confirmCancelChannel(order_id, refund, isBlacklist, reasonBl)
    if (res?.data?.success === true) {
      setOrders(orders.filter(_item => {
        if (_item.order_id === order_id) {
          return false
        }
        return true
      }))
      setVisible(false);
    }
  }


  const editMultiplePriority = () => {
    if (editMultipleModal && editMultipleModal.current) {
      const selectedKeys: number[] = []
      selectedRowKeys.forEach((value: React.Key) => {
        selectedKeys.push(parseInt(value.toString()))
      })
      editMultipleModal.current.showModal(selectedKeys, true, (data) => {
        setSelectedRowKeys([])
        setOrders(orders.map(item => {
          if (data.orders.includes(item.order_id)) {
            return {
              ...item,
              priority: data.priority
            }
          }
          return {
            ...item
          }
        }))

      })
    }
  }

  const editMultiple = () => {
    if (editMultipleModal && editMultipleModal.current) {
      const selectedKeys: number[] = []
      selectedRowKeys.forEach((value: React.Key) => {
        selectedKeys.push(parseInt(value.toString()))
      })
      editMultipleModal.current.showModal(selectedKeys, false, (data) => {
        setSelectedRowKeys([])
        setOrders(orders.map(item => {
          if (data.orders.includes(item.order_id)) {
            return {
              ...item,
              priority: data.priority,
              note: data.note,
              sub_need: data.sub_need,
              max_thread: data.max_thread
            }
          }
          return {
            ...item
          }
        }))
      })
    }
  }

  const deleteOrders = async (channelIds: string[]) => {
    try {
      const selectedKeys: number[] = []
      selectedRowKeys.forEach((value: React.Key) => {
        selectedKeys.push(parseInt(value.toString()))
      })
      const params = {
        orders: selectedRowKeys
      }
      let res = await postWithoutTokenFunciton(`order/deleteMulti`, params)
      if (res?.data?.success === true) {
        setOrders(orders.filter(item => {
          if (selectedRowKeys.includes(item.order_id)) {
            return false
          }
          return true
        }))
      }

    } catch (e: any) {
      alert(`Update failed ${e.message}`)
    }
  }
  const deleteMultiple = () => {
    if (editMultipleModal && editMultipleModal.current) {
      const selectedKeys: string[] = []
      selectedRowKeys.forEach((value: React.Key) => {
        selectedKeys.push(value.toString())
      })
      if (window.confirm('bạn có chắc chắn muốn xóa đơn này') == true) {
        // @ts-ignore
        deleteOrders(selectedRowKeys)
      }
    }
  }
  const addGroup = () => {
    if (groupName.length === 0) {
      alert('vui lòng điền tên nhóm')
      return
    }
    dispatch(actions.addGroupRequest(groupName))
    setGroupName('')
  }


  const searchByChannelID = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => {
      setCurrentChannelID(e.target.value)
    }, 1000)()
  }



  const remapOrders: OrderModel[] = orders.filter((order: OrderModel, index: number) => {
    if (currentUser !== undefined && order.user_id !== currentUser.id) {
      return false
    }
    if (currentService !== undefined && order.service_id !== currentService.service_id || currentService === undefined && valueService !== 0) {
      return false
    }
    if (currentGroup !== undefined && order.group_id !== currentGroup.id) {
      return false
    }

    if (priority !== -1 && order.priority !== priority) {
      return false
    }

    if (currentChannelID && order.channel_id !== currentChannelID) {
      return false
    }

    return true
  })


  remapOrders.sort((a: OrderModel, b: OrderModel) => {
    if(order_by == 0){
      return a.order_id - b.order_id
    }else if (order_by == 1) {
      return b.running - a.running
    } else if (order_by == 2) {
      return a.running - b.running
    } else if (order_by == 3) {
      return (b.current_sub - b.start_sub)/b.runed   - (a.current_sub - a.start_sub)/a.runed 
    } else if (order_by == 4) {
      return (a.current_sub - a.start_sub)/a.runed  - (b.current_sub - b.start_sub)/b.runed 
    } else if (order_by == 5) {
      return (a.sub_need - (a.current_sub - a.start_sub)) - (b.sub_need - (b.current_sub - b.start_sub))
    } else if (order_by == 6) {
      return (b.sub_need - (b.current_sub - b.start_sub)) - (a.sub_need - (a.current_sub - a.start_sub))
    } else {
      return a.running - b.running
    }
  });


  let totalThieu = 0
  let totalNeed = 0
  let totalRuned = 0
  remapOrders.forEach((item) => {
    totalThieu = totalThieu + item.sub_need - (item.current_sub - item.start_sub)
    totalNeed = totalNeed + item.sub_need
    totalRuned = totalRuned + item.runed
  })

  const onNameChange = (event: any) => { }


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let title = ''


  const hasSelected = selectedRowKeys.length > 0

  return (
    <div className='c-order'>
      <div className='page-header'>
        <div className='page-header__content'>
          <Row className='align-items-center'>
            <div className='col-lg-10 col-sm-12 c-order__header'>
              <span className='fw-bolder fs-3 mb-1'>
                <span className='fw-bolder fs-3 mb-1'>{title}</span>
                <span className='text-danger ml-2 fw-bold fs-7'>
                  ({format(orders.length, 0, 3)} {apiAlias === 'like' ? 'Video' : 'Kênh'}){' '}
                </span>
              </span>

              <p className='fw-bold c-order__list'>
                <span> - Tổng {apiAlias} đã đặt : {format(totalNeed, 0, 3)}</span>
                <span>  |  {apiAlias} thiếu : {format(totalThieu, 0, 3)}</span>
                <span>  |  {apiAlias} đã chạy : {format(totalRuned, 0, 3)}</span>
              </p>
            </div>
            <div className='col-lg-2 col-sm-12 text-center'>
              <div className='card-toolbar d-block'>
                <button
                  onClick={() => {
                    history.push(`/page/${apiAlias}Orders/create`)
                  }}
                  className='btn btn-sm btn-danger'
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} />  Thêm Kênh
                </button>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='justify-content-between'>
            <div className='card-toolbar w-100'>
              <Form layout='vertical' className='w-100'>
                <div className='row'>
                  <div className='col'>
                    <div className='row'>
                      {state !== 'lich-su' && (
                        <div className='col'>
                          {role === 'admin' && (
                            <Form.Item label='Trạng thái ưu tiên' className='mb-0'>
                              <Select
                                onChange={(value) => {
                                  setPriority(value)
                                }}
                                defaultValue={-1}
                                dropdownMatchSelectWidth={false}
                              >
                                <Select.Option value={-1}>Tất cả</Select.Option>
                                <Select.Option value={1}>Ưu tiên</Select.Option>
                                <Select.Option value={0}>Không Ưu tiên</Select.Option>
                              </Select>
                            </Form.Item>
                          )}
                        </div>
                      )}
                      <div className='col'>
                        <Form.Item label='Lọc theo user' className='mb-0'>
                          <Select
                            onChange={(value) => {
                              if (value === -1) {
                                setCurrentUser(undefined)
                              } else {
                                const user = accounts.find((usr) => usr.id === value)
                                setCurrentUser(user)
                              }
                            }}
                            defaultValue={-1}
                            dropdownMatchSelectWidth={false}
                            defaultActiveFirstOption
                          >
                            <Select.Option value={-1}>Tất cả user</Select.Option>
                            {accounts.map((item: AccountModel) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.username}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      {
                        /*
                        <div className='col'>
                        <Form.Item label='Lọc theo nhóm' className='mb-0'>
                          <Select
                            onChange={(value) => {
                              if (value === -1) {
                                setCurrentGroup(undefined)
                              } else {
                                const group = groups.find((g) => g.id === value)
                                setCurrentGroup(group)
                              }
                            }}
                            defaultValue={-1}
                            dropdownMatchSelectWidth={false}
                            defaultActiveFirstOption
                            optionLabelProp='label'
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space align='center' style={{ padding: '0 8px 4px' }}>
                                  <Input
                                    value={groupName}
                                    placeholder='Nhập tên nhóm'
                                    onChange={(e) => setGroupName(e.target.value)}
                                  />
                                  <Typography.Link
                                    onClick={addGroup}
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Thêm
                                  </Typography.Link>
                                </Space>
                              </>
                            )}
                          >
                            <Select.Option value={-1} label='Tất cả các nhóm'>
                              Tất cả các nhóm
                            </Select.Option>
                            {groups.map((item: Group) => (
                              <Select.Option key={item.id} value={item.id} label={item.name}>
                                <div className='d-flex justify-content-between'>
                                  <span>{item.name}</span>
                                  <Typography.Link
                                    onClick={(e) => {
                                      e.preventDefault()
                                      if (
                                        window.confirm('bạn có chắc chắn muốn xóa nhóm này') ===
                                        true
                                      ) {
                                        dispatch(actions.deleteGroupRequest(item.id))
                                      }
                                    }}
                                    style={{ whiteSpace: 'nowrap' }}
                                    className='text-danger'
                                  >
                                    Xoá
                                  </Typography.Link>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      */
                      }

                      <div className='col'>
                        <Form.Item label='Sắp xếp theo' className='mb-0'>
                          <Select
                            onChange={(value) => {
                              setOrder_by(value)
                            }}
                            defaultValue={0}
                            dropdownMatchSelectWidth={false}
                            defaultActiveFirstOption
                            optionLabelProp='label'
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                              </>
                            )}
                          >
                            <Select.Option value={0} label='Orderid'>
                              Orderid
                            </Select.Option>
                            <Select.Option value={1} label='Số luồng giảm dần'>
                              Số luồng giảm dần
                            </Select.Option>
                            <Select.Option value={2} label='Số luồng tăng dần'>
                              Số luồng tăng dần
                            </Select.Option>
                            <Select.Option value={3} label='Hiệu xuất giảm dần'>
                              Hiệu xuất giảm dần
                            </Select.Option>
                            <Select.Option value={4} label='Hiệu tăng tăng dần'>
                              Hiệu xuất tăng dần
                            </Select.Option>
                            <Select.Option value={5} label='Sub còn thiếu tăng dần'>
                              Sub còn thiếu tăng dần
                            </Select.Option>
                            <Select.Option value={6} label='Sub còn thiếu giảm dần'>
                              Sub còn thiếu giảm dần
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>

                      <div className='col'>
                        <Form.Item label='Lọc theo Service' className='mb-0'>
                          <Select
                            onChange={(value) => {
                              if (value === -1) {
                                setCurrentService(undefined)
                                setValueService(0)
                              } else {
                                const ord = orders?.find((ord) => ord.service_id === value)
                                setCurrentService(ord)
                                setValueService(value)
                              }
                            }}
                            defaultValue={-1}
                            dropdownMatchSelectWidth={false}
                            defaultActiveFirstOption
                          >
                            <Select.Option value={-1}>Tất cả dịch vụ</Select.Option>
                              {services?.length != 0 && services?.map((item: any) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                            
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive table--custom'>
            <div >
              <Affix offsetTop={10}>
                <div style={{ marginBottom: 16, background: '#fff' }} className='c-order__nav'>
                  <button
                    disabled={!hasSelected}
                    onClick={editMultiple}
                    className='btn btn-sm btn-primary'
                  >
                    <FontAwesomeIcon icon={['far', 'edit']} /> Cài đặt
                  </button>
                  <button
                    disabled={!hasSelected}
                    onClick={editMultiplePriority}
                    className='btn btn-sm btn-primary'
                  >
                    <FontAwesomeIcon icon={['far', 'edit']} /> Cài đặt ưu tiên
                  </button>
                  <button
                    disabled={!hasSelected}
                    onClick={deleteMultiple}
                    className='btn btn-sm btn-danger'
                  >
                    Delete
                  </button>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                  </span>
                </div>
              </Affix>
              <Table
                loading={loading}
                bordered
                pagination={false}
                rowSelection={rowSelection}
                columns={[
                  {
                    title: '#',
                    key: 'stt',
                    dataIndex: 'stt',
                    width: 40,
                    render: (value: any, record: OrderModel, index: any) => index + 1,
                  },
                  {
                    title: 'OrderID',
                    key: 'order_id',
                    dataIndex: 'order_id',
                    width: 50,
                    render: (value: any, item: OrderModel, index: any) => {
                      return (
                        <div className='text-center'>
                          <Tooltip placement="topRight" title={`===Lần đầu===\n Sub bắt đầu ${item.first_sub_start} , Sub order : ${item.first_sub_order} , Lần đầu : ${ moment.unix(item.first_time).format("DD/MM HH:mm")} , số lần BH ${item.count_bh} `} >
                            <span>{item.order_id}</span>
                          </Tooltip>
                          {
                            item.priority === 1 && <Badge pill color='success' className='m-1'>
                              <i className="feather icon-check-circle"></i>
                            </Badge>
                          }
                        </div>
                      )
                    },
                  },
                  {
                    title: 'ROLE',
                    key: 'order_id',
                    dataIndex: 'order_id',
                    width: 100,
                    render: (value: any, item: OrderModel, index: any) => {
                      return (
                        <div className='text-center'>
                          {item.user_id !== 1 && (
                           <span>{item.username}</span>
                          )}{' '}
                        </div>
                      )
                    },
                  },
                  {
                    title: `UID`,
                    key: 'channel_id',
                    dataIndex: 'channel_id',
                    width: 50,
                    render: (value: any, item: OrderModel, index: any) => {
                      return (
                        <>
                          <div>
                            <a
                              href={item.channel_id  ? 'https://www.youtube.com/channel/' + item.channel_id : item.order_link   }
                              className='text-dark fw-bolder text-hover-primary caption' data-title={item.order_link}
                              target='_blank'
                            >
                              {item.channel_id  ? item.channel_id : item.order_link}
                            </a>
                          </div>
                          
                        </>

                      )
                    },
                  },
                  {
                    title: 'Ngày Thêm',
                    key: 'insert_dateString',
                    width: 150,
                    dataIndex: 'insert_dateString',
                    render: (value: any, item: OrderModel, index: any) => {
                      return (
                        <>
                          {moment.unix(item.insert_date).format('DD/MM HH:mm')}
                          <span
                            style={{ fontSize: '12px' }}
                            className=' fw-bold  d-block'
                          ></span>
                        </>
                      )
                    },
                  },
                  {
                    title: 'Còn Thiếu',
                    key: 'sub_need',
                    dataIndex: 'sub_need',
                    width: 150,
                    render: (value: any, item: OrderModel, index: any) => {
                      const subNeedRun =
                        item.sub_need -
                        (item.current_sub - item.start_sub)
                      return (
                        <>
                          <span
                            style={{ fontSize: '12px' }}
                            className={
                              ' fw-bold  d-block font-size-10 ' +
                              (subNeedRun > 0 ? 'text-warning' : 'text-primary')
                            }
                          >
                            {format(Math.abs(subNeedRun) || 0, 0, 3) + '/' + format(item.sub_need || 0, 0, 3)}
                          </span>
                        </>
                      )
                    }
                  },
                  {
                    title: 'Sub bắt đầu/Hiện tại',
                    key: 'sub_start',
                    width: 200,
                    render: (value: any, record: OrderModel, index: any) => {
                      if (apiAlias === 'like') {
                        return `${record.start_like}/${record.current_like}`
                      } else {
                        return `${format(record.start_sub || 0, 0, 3)}/${format(record.current_sub || 0, 0, 3)}`
                      }
                    }
                  },
                  {
                    title: 'Đã chạy/Thực tế',
                    key: 'run',
                    width: 200,
                    render: (value: any, record: OrderModel, index: any) => {
                      const increase = record.current_sub - record.start_sub;
                      const number = Number(((increase / record.runed) * 100).toFixed(2));
                      const result = number >= 80 ? 'c-order__rate c-order__rate--green' : number < 80 && number > 50 ? 'c-order__rate c-order__rate--yellow' : 'c-order__rate c-order__rate--red';
                      return (
                        <div>
                          <p>
                            {format(record.runed || 0, 0, 3)}/
                            {format(increase || 0, 0, 3)}
                          </p>
                          <p>
                            
                            {increase &&
                              record.runed
                              ?
                              <span className={result}>
                                {
                                  number + ' %'
                                }
                              </span>
                              :
                              <span>0 %</span>

                            }
                          </p>
                        </div>
                      )
                    }

                  },
                  {
                    title: 'Luồng',
                    key: 'running',
                    width: 100,
                    render: (value: any, record: OrderModel, index: any) => {
                      return `${record.running}/${record.max_thread}`
                    }
                  },
                  {
                    title: 'Sub chờ',
                    key: 'sub_wait',
                    width: 100,
                    dataIndex: 'sub_wait'
                  },
                  {
                    title: 'Lần cuối',
                    width: 100,
                    key: 'last_get',
                    render: (value: any, record: OrderModel, index: any) => {
                      return `${moment(record.last_get, "yyyy-MM-DD HH:mm:ss").local().format('DD/MM HH:mm')}`
                    },
                  },
                  {
                    title: 'Update',
                    key: 'last_update',
                    width: 100,
                    render: (value: any, record: OrderModel, index: any) => {
                      return `${moment.unix(record.last_update).format('DD/MM HH:mm')}`
                    },
                  },
                  {
                    title: 'Service ID',
                    key: 'service_id',
                    dataIndex: 'service_id',
                    width: 100
                  },
                  {
                    title: 'NOTE',
                    key: 'note',
                    dataIndex: 'note',
                    width: 200,
                  },
                  {
                    width: 90,
                    title: '---',
                  
                    key: 'running',
                    render: (value: any, record: OrderModel, index: any) => {
                      if (state === "pending") {
                        return (
                          <Button
                            onClick={() => clickCancelAndRefund(record.order_id)}
                            className='c-order__btn c-order__btn--trash'
                          >
                            Hủy & hoàn tiền
                          </Button>
                        )
                      }
                      if (state === "wait") {
                        return <Button
                          onClick={() => clickCancelHandler(record)}
                          className='c-order__btn c-order__btn--trash'
                        >
                          <i className="feather icon-trash-2"></i> Hủy
                        </Button>
                      }
                      if (state === "cancel") {
                        return (
                          <Button
                            onClick={() => setCurrentCancelChannel(record.order_id)}
                            className='c-order__btn c-order__btn--trash'
                          >
                            Duyệt hủy
                          </Button>
                        )
                      }
                      return (
                        <>
                          <div className='d-flex flex-direction-column'>
                            {
                              /*
                               <Button
                              onClick={() => clickUpdateHandler(record)}
                              className='c-order__btn c-order__btn--edit'
                            >
                              <i className="feather icon-edit"></i> Edit
                            </Button>
                              */
                            }

                            {
                              <>
                                <Button
                                  onClick={() => clickShowListVideo(record)}
                                  className='c-order__btn c-order__btn'
                                >
                                  <i className="feather icon-completed"></i> List video
                                </Button>
                                <Button
                                  onClick={() => clickShowSubEveryday(record)}
                                  className='c-order__btn c-order__btn'
                                >
                                  <i className="feather icon-completed"></i> Số sub chạy
                                </Button>
                                <Button
                                  onClick={() => clickCompleteHandler(record)}
                                  className='c-order__btn c-order__btn'
                                >
                                  <i className="feather icon-completed"></i> Hoàn Thành
                                </Button>
                                <Button
                                  onClick={() => clickReloadVideo(record)}
                                  className='c-order__btn c-order__btn'
                                >
                                  <i className="feather icon-completed"></i> Reload Video
                                </Button>
                                <Button
                                  onClick={() => setCurrentCancelChannel(record.order_id)}
                                  className='c-order__btn c-order__btn--trash'
                                >
                                  <i className="feather icon-trash-2"></i> Hủy
                                </Button>
                              </>
                            }

                          </div>
                        </>
                      )
                    },
                  },
                ]}
                rowKey={(record) => record.order_id || 1}
                dataSource={remapOrders}
              />
            </div>
          </div>
          {/* end::Table container */}
        </div>
        <MultipleEditModal ref={editMultipleModal} />
      </div>
      <Modal
        visible={visible}
        title={"Bạn có muốn hủy đơn " + currentCancelChannel}
        onOk={() => { }}
        onCancel={() => setCurrentCancelChannel(-1)}
        footer={[
          <Button key="back" onClick={() => setCurrentCancelChannel(-1)}>
            Cancel
          </Button>,
          <Button key="submit" danger type="primary" loading={loading} onClick={() => clickConfirmCancelHandler(currentCancelChannel, false)}>
            Chấp nhận hủy
          </Button>,
          <Button
            type="dashed"
            danger
            loading={loading}
            onClick={() => clickConfirmCancelHandler(currentCancelChannel, true)}
          >
            Chấp nhận hủy và hoàn tiền
          </Button>,
        ]}
      >
        <Checkbox onChange={onChangeAddToBl}>Thêm vào blacklist</Checkbox>
        {isBlacklist && <Input placeholder="Reason" onChange={(e) => setReasonBl(e.target.value)}/> }
      </Modal>

      <Modal style={{ top: 100 }} title="List video" open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
        <Table dataSource={dataVideo} columns={columns} />
      </Modal>

      <Modal style={{ top: 100 }} title="Số sub theo ngày" open={isModalSubOpen} onOk={handleCancelSub} onCancel={handleCancelSub}>
        <Table dataSource={dataSubOfVideo} columns={columnsSub} />
      </Modal>

      <Modal
          visible={isShowCancelAndRefund}
          title={"Bạn có muốn hủy đơn " + currentCancelVideo}
          onOk={() => { }}
          onCancel={() => setCurrentCancelVideo(-1)}
          footer={[
            <Button key="back" onClick={() => setCurrentCancelVideo(-1)}>
              Cancel
            </Button>,
            <Button
              type="dashed"
              danger
              loading={loading}
              onClick={() => handleCancelRefund(currentCancelVideo, reason)}
            >
              Chấp nhận hủy và hoàn tiền
            </Button>,
          ]}
        >
          <select
            onChange={(e) => {
              setReason(e.target.value)
              console.log(e.target.value);
            }}>
            <option value={'0'}>Chọn lý do hủy</option>
            <option value={`Not enough video in this channel`}>Not enough video in this channel</option>
            <option value={`Channel die`}>Channel die</option>
          </select>
        </Modal>
    </div>
  )
}

export { OrderList }
