import { Divider, Form, Input, Select, Space, Table, Typography, Button, Tooltip } from 'antd'
import { Badge, Row } from 'reactstrap'
import {
  Card
} from 'reactstrap'
import { SearchOutlined } from '@ant-design/icons';
import { Group, OrderModel, Report } from './models/Order'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AccountModel } from 'app/modules/accounts/models/Account'
import DatePicker from 'react-datepicker'
import { Filter } from 'react-feather'
import { RootState } from 'setup'
import { actions } from './redux/OrdersRedux'
import { debounce, forEach } from 'lodash'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'
import { listHistorySubsribe, listHistoryByChannelID, refundOrder, restoreChannel, refundFullOrder } from './redux/OrdersCRUD';
import Chart from 'react-apexcharts'
import CountUp from 'react-countup'
import { getListAccount, getListRestore } from '../accounts/AccountCRUD';
import RestoreModal, { RestoreModalHandle } from './modals/RestoreModal';
type Props = {
  className: string
  state: string
  apiAlias: string
}

const HistoryList: React.FC<Props> = ({ apiAlias, className, state }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState('')
  const restoreModalHandle = useRef<RestoreModalHandle>(null)
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''
  const groups: Group[] =
    (useSelector<RootState>(({ orders }) => orders.groups, shallowEqual) as Group[]) || []
  const [currentGroup, setCurrentGroup] = useState<Group>()
  const [currentUserID, setCurrentUserID] = useState(-1)
  const [currentChannelID, setCurrentChannelID] = useState('')
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState<OrderModel[]>([])
  const [reports, setReports] = useState<any[]>([])
  const [accounts, setAccounts] = useState<AccountModel[]>([])
  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + -3)
  const [from, setFrom] = useState<Date>(defaultDate)
  const [to, setTo] = useState<Date>(new Date())
  const [fromRetore, setFromRetore] = useState<Date>(defaultDate)
  const [toRetore, setToRetore] = useState<Date>(new Date())
  const [arrRestore, setArrRestore] = useState<number[]>([])
  const [numberScanIndex, setNumberScanIndex] = useState(0)

  const fetchAccountTask = async () => {
    const rest = await getListAccount()
    if (rest?.data?.data) {
      setAccounts(rest?.data.data)
    }
  }

  const showRestoreModal = () => {
    if (restoreModalHandle && restoreModalHandle.current) {
      restoreModalHandle.current.showModal((data) => {

      })
    }
  }


  const reloadDataTask = async () => {
    setLoading(true)
    let res
    if (currentChannelID !== '') {
      res = await listHistoryByChannelID(currentChannelID);
    } else {
      res = await listHistorySubsribe(moment(from).format('DD-MM-YYYY'), moment(to).format('DD-MM-YYYY'), currentUserID, state === 'complete_history' ? 0 : 1)
    }
    if (res?.data?.data) {
      setOrders(res?.data?.data)
    }
    if (res?.data?.report) {
      setReports(res?.data?.report)
    }
    setLoading(false)
  }
  const restoreT = async () => {
    try {
      if (arrRestore.length <= 0) {
        return
      }
      const rest = await restoreChannel(arrRestore[0])
      let temparr = arrRestore;
      //console.log(temparr.length)
      temparr.shift()
      //console.log(temparr.length)
      setArrRestore([
        ...temparr
      ])
    } catch (ex: any) {
      console.log(ex.message)
    }

  }
  useEffect(() => {
    restoreT()
  }, [arrRestore])


  const autoRestoreLoad = async () => {
    const rest = await getListRestore(moment(fromRetore).format('DD-MM-YYYY'), moment(toRetore).format('DD-MM-YYYY'))
    if (rest?.data?.data) {
      setArrRestore(rest?.data?.data)
      setNumberScanIndex(rest?.data?.data.length)
    }
  }

  useEffect(() => {
    reloadDataTask()
    fetchAccountTask()
  }, [])

  const clickRestoreHandler = async (item: OrderModel) => {
    if (window.confirm('Yêu cầu bảo hành kênh') === true) {
      const res: any = await restoreChannel(item.order_id)
      console.log(res)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      } else {
        if (res?.data?.data?.error) {
          alert(res?.data?.data?.error)
        }

      }
    }
  }

  const clickRefund = async (item: OrderModel) => {
    if (window.confirm('Trả lại phần tiền thiếu') === true) {
      const res: any = await refundOrder(item.order_id)
      console.log(res)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      } else {
        if (res?.data?.data?.error) {
          alert(res?.data?.data?.error)
        }

      }
    }
  }

  const clickFullRefund = async (item: OrderModel) => {
    if (window.confirm('Trả lại toàn bộ phần tiền') === true) {
      const res: any = await refundFullOrder(item.order_id)
      console.log(res)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      } else {
        if (res?.data?.data?.error) {
          alert(res?.data?.data?.error)
        }

      }
    }
  }

  const addGroup = () => {
    if (groupName.length === 0) {
      alert('vui lòng điền tên nhóm')
      return
    }
    dispatch(actions.addGroupRequest(groupName))
    setGroupName('')
  }
  const searchByChannelID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentChannelID(e.target.value)
  }
  const totalSubscription = useMemo(
    () => reports?.reduce((total, report) => total + report.total, 0),
    [reports]
  )
  const option = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: '#447f72',
      width: 2,
      dashArray: 0,
    },
    title: {
      text: 'subscription',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: reports.map((rp) => rp.date),
    },
    markers: {
      size: 6,
      colors: '#447f72',
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
  }
  const chartDashboardMonitoring3AData = [
    {
      name: 'subscription',
      data: reports.map((rp) => rp.total),
    },
  ]
  let TableFormat = [
    {
      title: '',
      key: 'stt',
      dataIndex: 'stt',
      width: 50,
      render: (value: any, record: OrderModel, index: any) => index + 1,
    },
    {
      title: 'ID đơn hàng',
      key: 'order_id',
      dataIndex: 'order_id',
      width: 150,
      render: (value: any, item: OrderModel, index: any) => {
        return (
          <div className='text-center'>
            <Tooltip placement="topRight" title={`===Lần đầu===\n Sub bắt đầu ${item.first_sub_start} , Sub order : ${item.first_sub_order} , Lần đầu : ${moment.unix(item.first_time).format("DD/MM HH:mm")} , số lần BH ${item.count_bh} `} >
              <span
                style={{ fontSize: '12px' }}
                className=' fw-bold  d-block'
              >
                {item.order_id}
              </span>
            </Tooltip>

          </div>
        )
      },
    },
    {
      title: 'OrderLink',
      key: 'order_link',
      dataIndex: 'order_link',
      width: 100
    },
    {
      title: 'ID Chanel/User',
      key: 'channel_id',
      dataIndex: 'channel_id',
      width: 150,
      render: (value: any, item: OrderModel, index: any) => {
        return (
          <>
            <a
              href={'https://www.youtube.com/channel/' + item.channel_id}
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.channel_id}
            </a>
          </>
        )
      },
    },

    {
      title: 'Ngày tạo',
      key: 'start_date',
      dataIndex: 'start_date',
      width: 100,
      render: (value: any, item: OrderModel, index: any) => {
        return (
          <span
            style={{ fontSize: '12px' }}
            className='fw-bold d-block'
          >
            {item.start_time === undefined
              ? ''
              : moment.unix(item?.start_time).format('DD/MM HH:mm')}
          </span>
        )
      },
    },
    {
      title: 'Hoàn thành',
      key: 'end_date',
      width: 100,
      render: (value: any, item: OrderModel, index: any) => {
        return (
          <span
            style={{ fontSize: '12px' }}
            className=' fw-bold d-block'
          >
            {item?.end_time === undefined
              ? ''
              : moment.unix(item?.end_time).format('DD/MM HH:mm')}
          </span>
        )
      },
    },
    {
      title: `Bắt đầu`,
      key: 'sub_start',
      dataIndex: 'sub_start',
      width: 100,
      render: (value: any, item: OrderModel, index: any) => {
        return `${format(item.sub_start || 0, 0, 3)}`
      },
    },
    {
      title: `Sub Order`,
      key: 'sub_need',
      dataIndex: 'sub_need',
      width: 100,
      render: (value: any, item: OrderModel, index: any) => {
        return `${format(item.sub_oder_origin || 0, 0, 3)}`
      },
    },
    {
      title: `Sub Chạy`,
      key: 'sub_need',
      dataIndex: 'sub_need',
      width: 100,
      render: (value: any, item: OrderModel, index: any) => {
        return `${format(item.sub_need || 0, 0, 3)}`
      },
    },
    {
      title: `Kết thúc`,
      key: 'sub_view',
      width: 100,
      render: (value: any, record: OrderModel, index: any) => {
        return `${format(record.sub_end || 0, 0, 3)}`
      },
    },
    {
      title: 'Lý Do',
      key: 'cancel Reason',
      width: 150,
      render: (value: any, record: OrderModel, index: any) => {
        return record.cancel_reason
      },
    },

  ];

  if (state === "complete_history") {

    TableFormat.push({
      title: 'Bảo hành',
      key: 'money',
      width: 150,
      render: (value: any, record: OrderModel, index: any) => {
        return (
          <div>
            <Button
              color='neutral-primary'
              onClick={() => clickRestoreHandler(record)}
              className='mx-1 rounded-sm c-btnHistory'
            >
              Gửi yêu cầu
            </Button>
            <Button
              color='neutral-primary'
              onClick={() => clickRefund(record)}
              className='mx-1 rounded-sm c-btnHistory'
            >
              Refund
            </Button>
            <Button
              color='neutral-primary'
              onClick={() => clickFullRefund(record)}
              className='mx-1 rounded-sm c-btnHistory'
            >
              Full Refund
            </Button>
          </div>
        )
      },
    })
  }

  return (
    <div className='c-order'>
      <Card className='card-box mb-5 text-center'>
        <div className='card-box__inner'>
          <div className='card-box__inner__text'>

          
          <div className='card-header-alt'>
            <p className='font-weight-bold mb-0 text-black'>
              {'Số subscribe: ' +
                moment(from).format(intl.formatMessage({ id: 'DATE.MOMENT.FORMAT' })) +
                ' | ' +
                moment(to).format(intl.formatMessage({ id: 'DATE.MOMENT.FORMAT' }))
              }
            </p>
          </div>
          <h3 className='pt-1  display-3 font-weight-bold'>
            <span className='pr-1'>
              <CountUp
                start={0}
                end={totalSubscription}
                duration={4}
                separator=''
                delay={3}
                decimals={0}
                decimal=','
                prefix=''
                suffix=''
                formattingFn={(number) => format(number, 0, 3)}
              />
            </span>
          </h3>
          </div>
        </div>
        <div className='card-box__bottom'>
          <Chart options={option} series={chartDashboardMonitoring3AData} type='line' height={205} />
        </div>
      </Card>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='card-toolbar justify-content-between'>
            <div className='w-100'>
              <Form layout='vertical' className='w-100'>
                <div className='row'>
                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-12'>
                            <Form.Item
                              label={`Tìm theo Order id | Channel ID `}
                              className='mb-0'
                            >
                              <Input
                                placeholder={`Nhập Order id | Channel ID `}
                                onChange={searchByChannelID}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      {role === 'admin' ? (
                        <div className='col'>
                          <Form.Item label='Lọc theo user' className='mb-0'>
                            <Select
                              onChange={(value) => setCurrentUserID(value)}
                              defaultValue={-1}
                              dropdownMatchSelectWidth={false}
                              defaultActiveFirstOption
                            >
                              <Select.Option value={-1}>Tất cả user</Select.Option>
                              {accounts.map((item: AccountModel) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.username}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      ) : null}
                      <div className='col'>
                        <Form.Item label='Lọc theo nhóm' className='mb-0'>
                          <Select
                            onChange={(value) => {
                              if (value === -1) {
                                setCurrentGroup(undefined)
                              } else {
                                const group = groups.find((g) => g.id === value)
                                setCurrentGroup(group)
                              }
                            }}
                            defaultValue={-1}
                            dropdownMatchSelectWidth={false}
                            defaultActiveFirstOption
                            optionLabelProp='label'
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                              </>
                            )}
                          >
                            <Select.Option value={-1} label='Tất cả các nhóm'>
                              Tất cả các nhóm
                            </Select.Option>
                            {groups.map((item: Group) => (
                              <Select.Option key={item.id} value={item.id} label={item.name}>
                                <div className='d-flex justify-content-between'>
                                  <span>{item.name}</span>
                                  <Typography.Link
                                    onClick={(e) => {
                                      e.preventDefault()
                                      if (
                                        window.confirm('bạn có chắc chắn muốn xóa nhóm này') ===
                                        true
                                      ) {
                                        dispatch(actions.deleteGroupRequest(item.id))
                                      }
                                    }}
                                    style={{ whiteSpace: 'nowrap' }}
                                    className='text-danger'
                                  >
                                    Xoá
                                  </Typography.Link>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col'>
                        <Form.Item label='Từ ngày' className='mb-0'>
                          <DatePicker
                            dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                            className='form-control'
                            selected={from}
                            onChange={(date) => setFrom(date != null ? date : defaultDate)}
                            selectsStart
                            startDate={from}
                            endDate={to}
                          />
                        </Form.Item>
                      </div>
                      <div className='col'>
                        <Form.Item label='Đến ngày' className='mb-0'>
                          <DatePicker
                            dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                            className='form-control'
                            selected={to}
                            onChange={(date) => setTo(date != null ? date : new Date())}
                            selectsEnd
                            startDate={from}
                            endDate={to}
                            minDate={from}
                          />
                        </Form.Item>
                      </div>
                      <div className='col'>
                        <Form.Item label='---' className='mb-0'>
                          <Button type="primary"
                            icon={<SearchOutlined />}
                            loading={loading}
                            onClick={reloadDataTask}>
                            Reload dữ liệu
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive '>
            <Table
              loading={loading}
              bordered
              columns={TableFormat}
              dataSource={orders}
              pagination={false}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <RestoreModal ref={restoreModalHandle} />
    </div>
  )
}

export { HistoryList }
