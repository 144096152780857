import React from 'react'
import { HeaderUserbox
 } from 'app/layout/layout-components'
const navLogo = (props) => {
  let toggleClass = ['mobile-menu']
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, 'on']
  }

  return (
    <>
      <div className='w-100'>
        {/* <a href='#!' className='b-brand'>
          <div className='b-bg'>
            <i className='feather icon-trending-up' />
          </div>
          <span className='b-title'>MKBooster</span>
        </a>
        <a
          href='#!'
          className={toggleClass.join(' ')}
          id='mobile-collapse'
          onClick={props.onToggleNavigation}
        >
          <span />
        </a> */}
        <HeaderUserbox />
      </div>
    </>
  )
}

export default navLogo
