import 'react-toastify/dist/ReactToastify.css'

import { AnimatePresence, motion } from 'framer-motion'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import React, { Suspense } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import AuthInit from './modules/auth/redux/AuthInit'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { Routes } from './routing/Routes'

// @mui material components

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <AnimatePresence>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
                <ToastContainer />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
    </AnimatePresence>
  )
}

export { App }
