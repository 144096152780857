import { FC } from 'react'

const Error404: FC = () => {
  return (
    <>
      <div className='card-box p-settingCard text-center card'>
        <div className="card-header-alt">
          <div className="font-weight-bold d-flex align-items-center justify-center p-settingCard__heading">
            <i className="feather icon-settings mr-3">
            </i><h3>Page Not Found</h3>
            
          </div>
        </div>
      </div>



    </>
  )
}

export { Error404 }
