import * as actionTypes from 'setup/redux/DattaThemeOptions'

import React, {Component} from 'react'

import NavLeft from './NavLeft'
import NavRight from './NavRight'
import {connect} from 'react-redux'

class NavBar extends Component {
  render() {
    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', this.props.headerBackColor]
    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, 'headerpos-fixed']
    }

    let toggleClass = ['mobile-menu']
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, 'on']
    }

    return (
      <>
        <header className={headerClass.join(' ')}>
          <div className='m-header'>
            <span
              className={toggleClass.join(' ')}
              id='mobile-collapse1'
              href='!#'
              onClick={this.props.onToggleNavigation}
            >
              <span />
            </span>
            <a href='!#' className='b-brand'>
             
              <span className='b-title text-white'>MKBooster</span>
            </a>
          </div>
          <a className='mobile-menu' id='mobile-header' href='!#'>
            <i className='feather icon-more-horizontal' />
          </a>
          <div className='collapse navbar-collapse'>
            <NavLeft />
            <NavRight rtlLayout={this.props.rtlLayout} />
          </div>
        </header>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rtlLayout: state.DattaThemeOptions.rtlLayout,
    headerBackColor: state.DattaThemeOptions.headerBackColor,
    headerFixedLayout: state.DattaThemeOptions.headerFixedLayout,
    collapseMenu: state.DattaThemeOptions.collapseMenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleNavigation: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
