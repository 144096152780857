import './styles.scss'

import {Card, Col, Row, Form, FormGroup, Input, Label} from 'reactstrap'
import {Computer, Report} from 'app/modules/orders/models/Order'
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {PageTitle} from '../../../_metronic/layout/core'
import {RootState} from 'setup'
import {useIntl} from 'react-intl'

import { Switch,Table } from 'antd'
import { getListServices , deleteService } from 'app/modules/accounts/AccountCRUD'
import AddOrderServiceModal, { AddOrderServiceModalHandle } from './_modals/AddOrderServiceModal'

const ServiceList: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(false)
  const addOrderServiceModalHandle = useRef<AddOrderServiceModalHandle>(null)

  const fetchAccountTask = async () => {
    const rest = await getListServices()

    if (rest?.data?.data) {
      setServices(rest?.data.data)
    }
  }

  const removeService = async (service_id: any) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá dịch vụ này ?') == true) {
      const rest = await deleteService(service_id)
      if (rest?.data) {
        setServices(services.filter((item: any) => {
          if (item.id === service_id) {
            return false
          }
          return true
        }))
      }
    }
  }

  const showAddService = (service?: any) => {
    if (addOrderServiceModalHandle && addOrderServiceModalHandle.current) {
      if (!service) {
        addOrderServiceModalHandle.current.showModalAdd((data: any) => {
          setServices(services.concat(data))
        })
      } else {
        addOrderServiceModalHandle.current.showModalUpdate(service || 0, (data: any) => {
          const remap: any[] = services.map((item: any) => {
            if (item.id === service.id) {
              return data
            }
            return {
              ...item
            }
          })
          // @ts-ignore
          setServices(remap)
        })
      }
    }
  }

  useEffect(() => {
    fetchAccountTask()
  }, [])


  let TableFormat = [
    {
      title: '',
      key: 'stt',
      dataIndex: 'stt',
      width: 50,
      render: (value: any, record: any, index: any) => index + 1,
    },
    {
      title: 'ID',
      key: 'ID',
      dataIndex: 'id',
      width: 50,
      render: (value: any, record: any, index: any) => record.id,
    },
    {
      title: 'Name',
      key: 'Name',
      dataIndex: 'Name',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <span
            style={{ fontSize: '12px' }}
            className=' fw-bold  d-block'
          >
            {item.name}
          </span>
        )
      },
    },
    {
      title: 'Mô tả ',
      key: 'Mô tả',
      dataIndex: 'description',
      with: 50,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.description}
            </span>
          </>
        )
      },

    },
    {
      title: 'Threads',
      key: 'threads',
      dataIndex: 'threads',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.max_thread + " / " + item.max_thread_3000 + " / " + item.max_thread_5000}
            </span>
          </>
        )
      },
    },
    {
      title: 'Min',
      key: 'Min',
      dataIndex: 'Min',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.min}
            </span>
          </>
        )
      },
    },
    {
      title: 'Max',
      key: 'Max',
      dataIndex: 'Max',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.max}
            </span>
          </>
        )
      },
    },
    {
      title: 'Prices / 10 subs',
      key: 'prices',
      dataIndex: 'prices',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.price_per_10}
            </span>
          </>
        )
      },
    },
    {
      title: 'Ưu tiên',
      key: 'prices',
      dataIndex: 'prices',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.priority === 1 ? "Ưu tiên" : "-"}
            </span>
          </>
        )
      },
    },
    {
      title: 'Disable',
      key: 'Disable',
      dataIndex: 'Disable',
      width: 150,
      render: (value: any, item: any, index: any) => {
        return (
          <>
            <span
              className='text-dark fw-bolder text-hover-primary text-sm'
            >
              {item.enable === 1 ? "Đang chạy" : "Đã hủy"}
            </span>
          </>
        )
      },
    },
    {
      title: '---',
      key: 'actions',
      dataIndex: 'actions',
      width: 200,
      render: (value: any, item: any, index: any) => {
        return (
          <div>
            <button
              onClick={() => removeService(item.id)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              Remove
            </button>

            <button type='button' onClick={() => showAddService(item)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              Update
            </button>
          </div>
        )
      },
    }
  ];


  return (
    <div className='c-order'>
      <div className={`card `}>
        <div className='text-center'>
          <div className='divider mb-4 mt-3' />
          <button type='button' onClick={() => showAddService()} className='btn btn-primary mb-4'>
            {'Thêm dịch vụ'}
          </button>
        </div>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive '>
            <Table
              loading={loading}
              bordered
              columns={TableFormat}
              dataSource={services}
              pagination={false}
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <AddOrderServiceModal ref={addOrderServiceModalHandle} />
    </div>
  )
}
const ServicesPage: FC = () => {
  const intl = useIntl()
  const role: string =
    (useSelector<RootState>(({auth}) => auth.user?.role, shallowEqual) as string) || ''

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Cài đặt'}</PageTitle>
      {role === 'admin' ? (
        <Row>
          <Col lg='12'>
            <ServiceList />
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export {ServicesPage}
