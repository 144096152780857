import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import React, { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink as NavLinkStrap } from 'reactstrap'
import { RootState } from 'setup'
import { UserModel } from 'app/modules/auth/models/UserModel'
import avatar2 from '../../../assets/images/avatars/halo.jpg'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'
import { Link } from 'react-router-dom'

const HeaderUserbox: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  console.log('HeaderUserbox', user)
  const history = useHistory()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <>
  
      <div className="MuiBox-root css-1pecj7g">
        <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1dridtm">
          <div className="MuiCardContent-root css-1882gvv">
            <div className="MuiBox-root css-1ssrsgn">
              <i className="feather icon-user-check text-lg text-dark" aria-hidden="true"></i>
            </div>
            <div className="MuiBox-root css-uujcwg"><h6 className="MuiTypography-root MuiTypography-h6 css-ija0gt">Wellcome back {user.username} !</h6>
              <div className="MuiBox-root css-11926c4 font-bold"><span className="MuiTypography-root MuiTypography-caption css-i7eqfq"> - My balance : {format(user.balance, 0, 3)}</span>
              </div>
              <a className="btn-logout" onClick={()=>{  history.push(`/me`)}}>{'Lịch sử'}</a>
              <a className="btn-logout" onClick={logout}>{intl.formatMessage({ id: 'ACCOUNT.LOGOUT' })}</a>
            </div>
          </div>
        </div>
        
      </div>
    </>
  )
}

export default HeaderUserbox
