import './styles.scss'
import axios from 'axios';

import {
  Card,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap'
import { Computer, Report } from 'app/modules/orders/models/Order'



/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState, useRef } from 'react'
import { getReports, listComputer, exportCSV, updateStatus, listTasks, listStatisRp, listError, getSubStatis, getCountProfitp } from 'app/modules/orders/redux/OrdersCRUD'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Chart from 'react-apexcharts'
import CountUp from 'react-countup'
import DatePicker from 'react-datepicker'
import { Filter } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingRow from 'app/modules/orders/components/LoadingRow'
import { PageTitle } from '../../../_metronic/layout/core'
import { RootState } from 'setup'
import { actions } from 'app/modules/orders/redux/OrdersRedux'
import moment from 'moment'
import 'moment/locale/vi'
import { useIntl } from 'react-intl'
import { format } from 'utils/convert'
import { Affix } from 'antd';
import Swal from 'sweetalert2'

import { Modal } from 'react-bootstrap'
import ListCom from './ListCom';

export const BASE_URL = process.env.REACT_APP_API_URL

interface ParentProps {
  lackThread: number;
}


const DashboardWrapper: FC<ParentProps> = ({lackThread}) => {
  // Modal Account Die

  const intl = useIntl()
  const dispatch = useDispatch()
  const [reports, setReports] = useState<any[]>([])
  const [statisRp, setStatisRP] = useState<any>()
  
  const [computers, setComputers] = useState<Computer[]>([])

  let defaultDate = new Date()
  defaultDate.setDate(defaultDate.getDate() + -7)

  const [from, setFrom] = useState<Date>(defaultDate)
  const [to, setTo] = useState<Date>(new Date())
  const getComputerList = async () => {
    const response: any = await listComputer()
    if (response && response.data) {
      setComputers(response.data.data)
    }
  }

  const getStatisRp = async () => {
    const response: any = await listStatisRp()
    if (response && response.data) {
      setStatisRP(response.data.data)
    }
  }


  const submitFilter = async () => {
    const response: any = await getReports(
      moment(from).format('DD-MM-yyyy'),
      moment(to).format('DD-MM-yyyy'),
      'subscribe'
    )

    if (response && response.data.data) {
      setReports(response.data.data.report)
    }
  }

  useEffect(() => {
    getStatisRp()
  }, [])
  useEffect(() => {
    getComputerList()
  }, [])

  useEffect(() => {
    submitFilter()
  }, [to, from])

  const totalSubscription = useMemo(
    () => reports?.reduce((total_run, report) => total_run + parseInt(report.total_run), 0),
    [reports]
  )

  console.log("===total====" + totalSubscription)
  const totalThread = computers?.reduce((total, comp) => total + comp.run, 0) || 0
  computers?.sort((a: any, b: any) => {
    return b.computer_name - a.computer_name
  });

  const option = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: '#21d4fd',
      width: 2,
      dashArray: 0,
    },
    title: {
      text: 'subscribe',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: reports?.map((rp) => rp.date),
    },
    markers: {
      size: 6,
      colors: '#21d4fd',
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },

  }

  const chartDashboardMonitoring3AData = [
    {
      name: 'Subscription',
      data: reports?.map((rp) => rp.total_run),
    },
  ]
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''




  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Thống kê'}</PageTitle>
      {role === 'admin' ? (
        <>

          <div className='row'>
            <div className='col-lg-12 position-relative z-index-2'>
              <Card className='card-box mb-5 text-center'>
                <div className='card-box__inner'>
                  <div className="card-box__inner__text">
                    <div className='card-header-alt'>
                      <p className='font-weight-bold mb-0 text-black'>
                        {'Số subscribe: ' +
                          moment(from).format(intl.formatMessage({ id: 'DATE.MOMENT.FORMAT' })) +
                          ' | ' +
                          moment(to).format(intl.formatMessage({ id: 'DATE.MOMENT.FORMAT' }))}
                      </p>

                      <div className='z-10 position-absolute' style={{ top: 10, right: 10 }}>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            outline
                            color='dark'
                            className='d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill'
                          >
                            <Filter className='w-50' />
                          </DropdownToggle>
                          <DropdownMenu right className='dropdown-menu-xxl p-0  c-chart'>
                            <Card className='p-2 shadow-xxl'>
                              <FormGroup>
                                <Label>{intl.formatMessage({ id: 'DATE.FROM_DATE' })}:</Label>
                                <InputGroup>
                                  <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                      <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <DatePicker
                                    dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                                    className='form-control'
                                    selected={from}
                                    onChange={(date) => setFrom(date != null ? date : defaultDate)}
                                    selectsStart
                                    startDate={from}
                                    endDate={to}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup>
                                <Label>{intl.formatMessage({ id: 'DATE.TO_DATE' })}:</Label>
                                <InputGroup>
                                  <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                      <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <DatePicker
                                    dateFormat={intl.formatMessage({ id: 'DATE.FORMAT' })}
                                    className='form-control'
                                    selected={to}
                                    onChange={(date) => setTo(date != null ? date : new Date())}
                                    selectsEnd
                                    startDate={from}
                                    endDate={to}
                                    minDate={from}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Card>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    <h3 className='pt-1 pb-2 display-3 mt-1 font-weight-bold'>
                      <span className='pr-1'>
                        <CountUp
                          start={0}
                          end={totalSubscription}
                          duration={4}
                          separator=''
                          delay={3}
                          decimals={0}
                          decimal=','
                          prefix=''
                          suffix=''
                          formattingFn={(number) => format(number, 0, 3)}
                        />
                      </span>
                    </h3>
                  </div>

                </div>
                <div className='card-box__bottom'>
                  <Chart options={option} series={chartDashboardMonitoring3AData} type='line' height={205} />
                </div>
              </Card>
            </div>
            <div className='col-lg-12 position-relative z-index-2'>
              <div className="row ">
                <div className="col-lg-4 col-md-6 col-12 ">
                  <div className="card bg-gradient-secondary">
                    <div className="card-body  c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-activity text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold opacity-7">{'Đang chạy'}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(statisRp?.total_run, 0, 3)} đơn
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" style={{ opacity: .8 }}>
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 ">
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-alert-circle text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{'Chờ hủy'}</p>
                        <h5 className=" font-weight-bolder mb-0">
                          {format(statisRp?.total_cancel, 0, 3)} đơn
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" style={{ opacity: .9 }}>
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 ">
                  <div className="card bg-gradient-secondary">
                    <div className="card-body c-intro">
                      <div className="icon icon-shape  shadow text-center border-radius-md">
                        <i className="feather icon-clock  text-lg text-white" aria-hidden="true"></i>
                      </div>
                      <div className="numbers">
                        <p className=" text-sm mb-0 text-capitalize font-weight-bold opacity-7">{"Chờ duyệt"}</p>
                        <h5 className="font-weight-bolder mb-0">
                          {format(statisRp?.total_pending, 0, 3)} đơn
                        </h5>
                      </div>
                      <svg className="editorial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none">
                        <defs>
                          <linearGradient id="wave-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#21d4fd" />
                            <stop offset="100%" stopColor="#2152ff" />
                          </linearGradient>
                          <path id="gentle-wave"
                            d="M-160 44c30 0 
                 58-18 88-18s
                 58 18 88 18 
                 58-18 88-18 
                 58 18 88 18
                 v44h-352z" />
                        </defs>
                        <g className="parallax" >
                          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />

                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <ListCom lackThread={statisRp?.lackThread}/>             
          </div>
        </>
      ) : null}
    </>
  )
}

export { DashboardWrapper }
