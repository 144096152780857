import axios from 'axios'
import { postWithoutTokenFunciton,getFunciton } from 'utils/ApiHelper'
import { AccountModel , PointModel } from './models/Account'

export async function getListAccount() {
  const res:any = await getFunciton("user/list")
  return res
}

export async function getListBlacklist() {
  const res:any = await getFunciton("channel/blackList")
  return res
}

export async function deleteBlacklist(channel_id: string) {
  const res:any = await getFunciton("channel/delBlackList/"+channel_id,)
  return res
}


export async function getListRestore(fromDate:string,toDate:String) {
  const res:any = await getFunciton("order/restorelist?fromDay="+fromDate+"&toDay="+toDate)
  return res
}

export async function updateAccount(user_id:number,max_threads:number,discount:number) {
  const res:any = await postWithoutTokenFunciton("user/update/"+user_id,{
    max_threads,
    discount
  })
  return res
}

export async function addPoint(user_id:number,point:number) {
  const res:any = await postWithoutTokenFunciton("/point/change/"+user_id,{
    point
  })
  return res
}

export async function listPoint(user_id:number) {
  const res:any = await getFunciton("/point/list/"+user_id)
  return res
}

export async function userStatis(from:string, to:string, user_id:number) {
  const res:any = await getFunciton("/user/statistic/"+user_id+'?from=' + from + '&to=' + to)
  return res
}

export async function getListServices() {
  const res:any = await getFunciton("service/list")
  return res
}

export async function addService(data:any) {
  const res:any = await postWithoutTokenFunciton("service/add",data)
  return res
}
export async function updateService(data:any,service_id:number) {
  const res:any = await postWithoutTokenFunciton("service/update/"+service_id,data)
  return res
}
export async function deleteService(service_id:number) {
  const res:any = await getFunciton("service/delete/"+service_id)
  return res
}