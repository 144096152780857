import * as actionTypes from 'setup/redux/DattaThemeOptions'

import React, {Component} from 'react'

import {Dropdown} from 'react-bootstrap'
import {connect} from 'react-redux'
import windowSize from 'react-window-size'

class NavLeft extends Component {
  render() {
    return <ul className='navbar-nav mr-auto'></ul>
  }
}

const mapStateToProps = (state) => {
  return {
    isFullScreen: state.DattaThemeOptions.isFullScreen,
    rtlLayout: state.DattaThemeOptions.rtlLayout,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFullScreen: () => dispatch({type: actionTypes.FULL_SCREEN}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavLeft))
