import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal
} from "reactstrap"
import { Group, OrderForm, OrderPost } from '../../models/Order'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { RootState } from 'setup'
import { postWithoutTokenFunciton } from "utils/ApiHelper"

type Props = {

}
export type RestoreModalHandle = {
    showModal: (onCompleted: (data: any) => void) => void;
};


const RestoreModal = forwardRef<RestoreModalHandle, Props>((props, ref) => {

    const [show, setVisible] = useState(false);
    const [callback, setCallBack] = useState<(data: any) => void>();
    const [listOrder, setListOrder] = useState('');
    const [result, setResult] = useState('');
    const [restoring, setRestoring] = useState(false);

    useImperativeHandle(ref, () => ({
        showModal
    }));

    const dismissModal = () => {
        setVisible(false);
    }
    const updateOrder = async () => {
        try {
            setRestoring(true)
            const arr=listOrder.trim().split(",");
            let res = await postWithoutTokenFunciton(`order/checkrestorebylist`, {
                channels:arr
            });

            if (res?.data.data) {
                callback&&callback(res?.data.data)
            }
            dismissModal()
            setRestoring(false)



        } catch (e: any) {
            alert(`Update failed ${e.message}`);
        }

    }

    const showModal = (onCompleted: (data: any) => void) => {
        setVisible(true);
        setCallBack(() => onCompleted);
    }

    return (
        <Modal isOpen={show}
            modalTransition={{ timeout: 500 }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Bảo hành đơn</h5>
                </div>
                <div className="modal-body">
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Danh sách đơn sẽ bảo hành
                            </Label>
                            <Input
                                id="listOrder"
                                name="listOrder"
                                value={listOrder}
                                style={{height:'150px'}}
                                className="form-control form-control-solid"
                                placeholder="Order cách nhau dấu ,"
                                type="textarea" 
                                onChange={(e) => setListOrder(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={dismissModal} className="btn btn-light" >Thoát</button>
                    <button type="button" disabled={restoring} onClick={updateOrder} className="btn btn-primary">{restoring?"Đang bảo hành":"Bảo hành"}</button>
                </div>
            </div>
        </Modal>
    )
});

export default RestoreModal