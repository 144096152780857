import './../../../assets/scss/style.scss';

import * as Yup from 'yup'
import * as auth from '../redux/AuthRedux'

import { Button, Card, Col, FormGroup, Input } from 'reactstrap'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { NavLink as NavLinkStrap } from 'reactstrap'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import clsx from 'clsx'
/* eslint-disable jsx-a11y/anchor-is-valid */
import people3 from '../../../assets/images/stock-photos/people-3.jpg'
import { register } from '../redux/AuthCRUD'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { copyFileSync } from 'fs';

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

export function Registration() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.INVALID' },
          {
            name: 'Email',
          }
        )
      )
      .min(
        3,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MIN_LENGTH' },
          {
            min: 3,
          }
        )
      )
      .max(
        50,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MIN_LENGTH' },
          {
            min: 50,
          }
        )
      )
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          {
            name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }),
          }
        )
      ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MIN_LENGTH' },
          {
            min: 3,
          }
        )
      )
      .max(
        50,
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.MIN_LENGTH' },
          {
            min: 50,
          }
        )
      )
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          {
            name: intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' }),
          }
        )
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          {
            name: intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' }),
          }
        )
      )
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({ id: 'AUTH.VALIDATION.CONFIRM_PASSWORD_DIDNOTMATCH' })
        ),
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: 'AUTH.VALIDATION.AGREEMENT_REQUIRED' })
    ),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.password)
          .then((data: any) => {
            setLoading(false)
            if (data.data.success === false) {
              setSubmitting(false)
              setStatus(data.data.error)
            } else {
              dispatch(auth.actions.login(data.data.data.token))
            }
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >


        <div className="">
          <div className="">
            
            <div className="card">
              <div className="card-body text-center">
                <h3 className="mb-4"> {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE' })}</h3>
                <p className='text-black-50'>
                  {intl.formatMessage({ id: 'AUTH.REGISTER.DESC' })}
                </p>
                <p>
                  {formik.status && (
                    <div className='px-4'>
                      <div className='mb-lg-15 alert alert-danger rounded-sm'>
                        <div className='alert-text'>{formik.status}</div>
                      </div>
                    </div>
                  )}
                </p>
                <div className="input-group mb-3">
                  <Input
                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  <div className="input-group mb-3">
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-group mb-4">
                  <Input
                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD.PLACEHOLDER' })}
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <div className="input-group mb-3">
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </p>
                </div>

                <div className="input-group mb-4">
                  <Input
                    type='password'
                    placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                      {
                        'is-invalid':
                          formik.touched.changepassword && formik.errors.changepassword,
                      },
                      {
                        'is-valid':
                          formik.touched.changepassword && !formik.errors.changepassword,
                      }
                    )}
                  />
                  <div className="input-group mb-3">
                    {formik.touched.changepassword && formik.errors.changepassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  size='lg'
                  className='btn-block text-uppercase font-weight-bold font-size-sm'
                  color='primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
                </Button>
                <p className='text-center text-black-50 mt-3'>
                  <Link to='/auth/login' className='text-first link-primary fw-bolder'>
                    {intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <Card className='rounded-sm modal-content p-3 bg-white-10'>
          <Card className='overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0'>
            <div className='hero-wrapper bg-composed-wrapper h-100 rounded-top'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--image rounded-top opacity-4' />
                <div className='bg-composed-wrapper--bg rounded-top' />
                <div className='bg-composed-wrapper--content text-center pt-5'>
                  <div className='text-white'>
                    <h1 className='font-size-xxl mb-1 font-weight-bold'>
                      {intl.formatMessage({ id: 'AUTH.REGISTER.TITLE' })}
                    </h1>
                    <p className='mb-0 text-black-50'>
                      {intl.formatMessage({ id: 'AUTH.REGISTER.DESC' })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-white rounded pt-4 pt-lg-0'>
              <Col lg='10' xl='9' className='z-over pt-5 pt-lg-4 pb-4 mx-auto'>
                {formik.status && (
                  <div className='px-4'>
                    <div className='mb-lg-15 alert alert-danger rounded-sm'>
                      <div className='alert-text'>{formik.status}</div>
                    </div>
                  </div>
                )}
                <div className='px-4 py-4'>
                  <FormGroup>
                    <label className='font-weight-bold'>
                      {intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                    </label>
                    <Input
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <div className='d-flex justify-content-between'>
                      <label className='font-weight-bold'>
                        {intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                      </label>
                    </div>
                    <Input
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD.PLACEHOLDER' })}
                      type='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                    <div className='text-muted'>
                      Use 8 or more characters with a mix of letters, numbers & symbols.
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className='d-flex justify-content-between'>
                      <label className='font-weight-bold'>
                        {intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                      </label>
                    </div>
                    <Input
                      type='password'
                      placeholder={intl.formatMessage({ id: 'AUTH.INPUT.CONFIRM_PASSWORD' })}
                      autoComplete='off'
                      {...formik.getFieldProps('changepassword')}
                      className={clsx(
                        {
                          'is-invalid':
                            formik.touched.changepassword && formik.errors.changepassword,
                        },
                        {
                          'is-valid':
                            formik.touched.changepassword && !formik.errors.changepassword,
                        }
                      )}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                      </div>
                    )}
                  </FormGroup>

                  <Button
                    size='lg'
                    className='btn-block text-uppercase font-weight-bold font-size-sm'
                    color='primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
                  </Button>
                  <div className='text-center text-black-50 mt-3'>
                    <Link to='/auth/login' className='text-first link-primary fw-bolder'>
                      {intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}
                    </Link>
                  </div>
                </div>
              </Col>
            </div>
          </Card>
        </Card> */}
      </form>
    </>
  )

}
