import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal
} from "reactstrap"

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { addService, updateService } from "app/modules/accounts/AccountCRUD";


type Props = {

}
export type AddOrderServiceModalHandle = {
    showModalAdd: (onCompleted: (data: any) => void) => void;
    showModalUpdate: (service: any, onCompleted: (data: any) => void) => void;
};


const AddOrderServiceModal = forwardRef<AddOrderServiceModalHandle, Props>((props, ref) => {


    const dispatch = useDispatch()

    const [show, setVisible] = useState(false);
    const [service_id, setService_id] = useState(0);
    const [service_name, setService_name] = useState('');
    const [description, setDescription] = useState('');
    const [price_per_10, setPrice_per_10] = useState(0);
    const [max_thread, setMax_thread] = useState(0);
    const [max_thread_3000, setMax_thread_3000] = useState(0);
    const [max_thread_5000, setMax_thread_5000] = useState(0);
    const [min, setMin] = useState(100);
    const [max, setMax] = useState(60000);
    const [priority, setPriority] = useState(0);
    const [callback, setCallBack] = useState<(data: any) => void>();

    useImperativeHandle(ref, () => ({
        showModalAdd,showModalUpdate
    }));

    const dismissModal = () => {
        setVisible(false);
    }
    const updateOrder = async () => {
        const data = {
            "name": service_name,
            "description": description,
            "price_per_10": price_per_10,
            "max_thread": max_thread,
            "max_thread_3000": max_thread_3000,
            "max_thread_5000": max_thread_5000,
            "priority": priority,
            min,
            max
        }
        if (service_id == 0) {
            const res = await addService(data)
            if (res?.data) {
                callback && callback(res?.data?.data);
                dismissModal();
            }
        } else {
            const res = await updateService(data,service_id)
            if (res?.data) {
                callback && callback(res?.data?.data);
                dismissModal();
            }
        }
    }

    const showModalAdd = (onCompleted: (data: any) => void) => {
        setVisible(true);
        setCallBack(() => onCompleted);
    }
    const showModalUpdate = (service: any, onCompleted: (data: any) => void) => {
        setService_id(service.id)
        setService_name(service.name)
        setDescription(service.description)
        setPrice_per_10(service.price_per_10)
        setMax_thread(service.max_thread)
        setMax_thread_3000(service.max_thread_3000)
        setMax_thread_5000(service.max_thread_5000)
        setMin(service.min)
        setMax(service.max)
        setPriority(service.priority)
        setVisible(true);
        setCallBack(() => onCompleted);
    }


    return (
        <Modal isOpen={show}
            modalTransition={{ timeout: 500 }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Thêm dịch vụ</h5>
                </div>
                <div className="modal-body">
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Tên dịch vụ
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                value={service_name}
                                className="form-control form-control-solid"
                                placeholder="Name..."
                                onChange={(e) => setService_name(e.target.value)}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                Mô tả
                            </Label>
                            <Input
                                id="description"
                                name="description"
                                value={description}
                                className="form-control form-control-solid"
                                placeholder="Mo ta..."
                                onChange={(e) => setDescription(e.target.value)}
                                type="textarea"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'Luồng < 3000'}
                            </Label>
                            <Input
                                id="max_thread"
                                name="max_thread"
                                value={max_thread}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setMax_thread(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'3000 < Luồng < 5000'}
                            </Label>
                            <Input
                                id="max_thread_3000	"
                                name="max_thread_3000	"
                                value={max_thread_3000}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setMax_thread_3000(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'5000 < Luồng '}
                            </Label>
                            <Input
                                id="max_thread_5000"
                                name="max_thread_5000"
                                value={max_thread_5000}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setMax_thread_5000(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'Số sub order min'}
                            </Label>
                            <Input
                                id="min"
                                name="min"
                                value={min}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setMin(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                             {'Số sub order max'}
                            </Label>
                            <Input
                                id="max"
                                name="max"
                                value={max}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setMax(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'prices / 10 subs'}
                            </Label>
                            <Input
                                id="price_per_10"
                                name="price_per_10"
                                value={price_per_10}
                                className="form-control form-control-solid"
                                placeholder="ví dụ : 1000"
                                onChange={(e) => setPrice_per_10(parseInt(e.target.value))}
                                type="number"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail" className="required form-label">
                                {'Ưu tiên'}
                            </Label>
                            <Input
                                onChange={(e) => setPriority(parseInt(e.target.value))}
                                className="form-control form-control-solid"
                                type="select"
                                value={priority}
                            >
                                <option value={0}>
                                    Không
                                </option>
                                <option value={1}>
                                    Có
                                </option>
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={dismissModal} className="btn btn-light" >Thoát</button>
                    <button type="button" onClick={updateOrder} className="btn btn-primary">Lưu thông tin mới</button>
                </div>
            </div>
        </Modal>
    )
});

export default AddOrderServiceModal