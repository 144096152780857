import {
  Computer,
  Group,
  OrderForm,
  OrderModel,
  OrderPost,
  OrderUpdateForm,
  Report,
} from '../models/Order'
import {
  addGroup,
  addOrder,
  deleteChannel,
  deleteGroup,
  deleteHistoryError,
  getListGroups,
  getListOrder,
  getReports,
  getSetting,
  listComputer,
  listHistory,
  updateOrder,
  cancelChannel,
  restoreChannel,
  updateSetting,
  confirmCancelChannel,
  completeChannel
} from './OrdersCRUD'
import {put, takeLatest} from 'redux-saga/effects'

import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  RequestOrders: '[Orders] Requested',
  RequestSetting: '[Setting] Requested',
  RequestRemoveError: '[Setting] Request Remove Error',
  RequestUpdateSetting: '[Setting] Request Update Setting',
  UpdateSettingSuccess: '[Setting] Update Setting Success',
  RequestSettingSuccess: '[Setting] Request Setting Success',
  OrdersLoadedSuccess: '[Orders] Loaded succcess',
  OrdersLoadedFail: '[Orders] load fail',
  AddOrderRequest: '[Orders] Add Order Request',
  RefreshCreate: '[Orders] Refresh Create',
  ClearError: '[Orders] Clear Error',
  AddOrderSuccess: '[Orders] Add Order Success',
  AddOrderFail: '[Orders] Add Order Fail',
  ShowcurrentOrder: '[orders] Show Account',
  RequestUpdate: '[orders] Requested Update',
  UpdateSuccess: '[orders] Update Success',
  UpdateFail: '[orders] Update Fail',
  ClearSelected: '[orders] Clear selected account',
  GroupLoadedRequest: '[orders] Group Loaded Request',
  GroupLoadedSuccess: '[orders] Group Loaded Success',
  GroupLoadedFail: '[orders] Group Loaded Fail',
  GroupAddRequest: '[orders] GroupAddRequest',
  GroupAddSuccess: '[orders] Group Add Success',
  GroupAddFail: '[orders] Group Add Fail',
  GroupDeleteRequest: '[orders] Group Delete Request',
  GroupDeleteSuccess: '[orders] Group Delete Success',
  SelectGroup: '[orders] Select Group',
  DeleteOrderRequest: '[orders] Delete Order Request',

  ConfirmCancelOrderRequest: '[orders] Confirm Cancel Order Request',
  ConfirmCancelOrderFail: '[orders] Confirm Cancel Order Fail',
  DeleteOrderSuccess: '[orders] Delete Order Success',
  //
  CancelOrderRequest: '[orders] Cancel Order Request',
  CancelOrderSuccess: '[orders] Cancel Order Success',
  //
  CompleteOrderRequest: '[orders] Complete Order Request',
  CompleteOrderSuccess: '[orders] Complete Order Success',
  //
  RestoreOrderRequest: '[orders] Restore Order Request',
  RestoreOrderSuccess: '[orders] Restore Order Success',
  //
  ListComputerLoadedRequest: '[ListComputer] ListComputer Loaded Request',
  ListComputerLoadedSuccess: '[ListComputer] ListComputer Loaded Success',
  ListComputerLoadedFail: '[ListComputer] ListComputer Loaded Fail',
  ReportsRequest: '[Reports] Reports Loaded Request',
  ReportsLoadSuccess: '[Reports] Reports Loaded Success',
  ChangeFromTo: '[ChangeFromTo] ChangeFromTo',
}
let defaultFromDate = new Date()
defaultFromDate.setDate(defaultFromDate.getDate() + -7)
const initialorderstate: Iorderstate = {
  orders: [],
  loading: false,
  adding: false,
  errors:[],
  groups: [],
  computers: [],
  reports: [],
  currentOrder: undefined,
  currentGroup: undefined,
  channel_prior: 0,
  computersLoading: false,
  from: defaultFromDate,
  to: new Date(),
  message: '',
}
export interface Iorderstate {
  orders: OrderModel[]
  computers: Computer[]
  loading: boolean
  computersLoading: boolean
  adding: boolean
  currentOrder?: OrderModel
  groups: Group[]
  reports: Report[]
  errors:any[]
  currentGroup?: Group
  channel_prior: number
  from: Date
  message: string
  to: Date
}

export const reducer = persistReducer(
  {storage, key: 'v1-orders', whitelist: []},
  (state: Iorderstate = initialorderstate, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.ListComputerLoadedRequest: {
        return {
          ...state,
          computers: [],
          computersLoading: true,
        }
      }
      case actionTypes.ListComputerLoadedSuccess: {
        return {
          ...state,
          computers: action.payload?.computers || [],
          computersLoading: false,
        }
      }
      case actionTypes.RequestOrders: {
        return {
          ...state,
          orders: [],
          loading: true,
        }
      }
      case actionTypes.ChangeFromTo: {
        return {
          ...state,
          from: action.payload?.from,
          to: action.payload?.to,
        }
      }
      case actionTypes.ReportsRequest: {
        return {
          ...state,
          reports: [],
        }
      }
      case actionTypes.ReportsLoadSuccess: {
        return {
          ...state,
          reports: action.payload?.reports,
        }
      }
      case actionTypes.OrdersLoadedSuccess: {
        return {
          ...state,
          orders: action.payload?.orders || [],
          loading: false,
        }
      }
      case actionTypes.GroupLoadedSuccess: {
        return {
          ...state,
          groups: action.payload?.groups,
        }
      }
      case actionTypes.SelectGroup: {
        return {
          ...state,
          currentGroup: action.payload?.group,
        }
      }
      case actionTypes.GroupDeleteSuccess: {
        return {
          ...state,
          groups: state.groups.filter((item: Group) => {
            if (item.id === action.payload?.id) {
              return false
            }
            return true
          }),
        }
      }
      case actionTypes.ConfirmCancelOrderRequest: {
        return {
          ...state,
          loading:true
        }
      }
      case actionTypes.ConfirmCancelOrderFail: {
        return {
          ...state,
          loading:false
        }
      }
      
      case actionTypes.DeleteOrderSuccess: {
        const apiAlias = window.apiAlias || 'subscribe'
        if (apiAlias === 'like') {
          return {
            ...state,
            orders: state.orders.filter((item: OrderModel) => {
              if (item.video_id === action.payload?.channel_id) {
                return false
              }
              return true
            }),
          }
        }
        return {
          ...state,
          orders: state.orders.filter((item: OrderModel) => {
            if (item.channel_id === action.payload?.channel_id) {
              return false
            }
            return true
          }),
        }
      }
      case actionTypes.CancelOrderSuccess: {
        return {
          ...state,
          orders: state.orders.filter((item: OrderModel) => {
            if (item.channel_id === action.payload?.channel_id) {
              return false
            }
            return true
          }),
        }
      }
      case actionTypes.RestoreOrderSuccess: {
        return {
          ...state,
          orders: state.orders.filter((item: OrderModel) => {
            if (item.order_id === action.payload?.order_id) {
              return false
            }
            return true
          }),
        }
      }


      case actionTypes.OrdersLoadedFail: {
        return {
          ...state,
          orders: [],
          loading: false,
        }
      }
      case actionTypes.RequestUpdate: {
        return {
          ...state,
          loading: true,
        }
      }
      case actionTypes.UpdateSuccess: {
        let remaporders = []
        if (action.payload?.order?.channel_id) {
          remaporders = state.orders.map((item: OrderModel) => {
            if (item.channel_id === action.payload?.order?.channel_id) {
              return action.payload?.order
            } else {
              return item
            }
          })
        } else if (action.payload?.order?.video_id) {
          remaporders = state.orders.map((item: OrderModel) => {
            if (item.video_id === action.payload?.order?.video_id) {
              return action.payload?.order
            } else {
              return item
            }
          })
        }

        return {
          ...state,
          orders: remaporders,
          loading: false,
          currentOrder: undefined,
        }
      }
      case actionTypes.UpdateFail: {
        return {
          ...state,
          loading: false,
        }
      }
      case actionTypes.AddOrderRequest: {
        return {
          ...state,
          adding: true,
          message: '',
        }
      }

      case actionTypes.RefreshCreate: {
        return {
          ...state,
          adding: false,
          message: '',
        }
      }
      case actionTypes.ClearError: {
        return {
          ...state,
          errors: [],
          message: '',
        }
      }
      case actionTypes.GroupAddSuccess: {
        return {
          ...state,
          groups: state.groups.concat(action.payload?.group),
        }
      }
      case actionTypes.AddOrderSuccess: {
        return {
          ...state,
          adding: false,
          orders: state.orders.concat(action.payload?.order),
          message: '',
        }
      }
      case actionTypes.RequestSettingSuccess: {
        return {
          ...state,
          channel_prior: action.payload.channel_prior,
        }
      }
      case actionTypes.AddOrderFail: {
        return {
          ...state,
          adding: false,
          message: action.payload?.message,
        }
      }
      case actionTypes.ShowcurrentOrder: {
        return {
          ...state,
          currentOrder: action.payload?.currentOrder,
        }
      }
      case actionTypes.ClearSelected: {
        return {
          ...state,
          currentOrder: action.payload?.currentOrder,
        }
      }
      default:
        return state
    }
  }
)

export const actions = {
  requestOrders: (state: string) => ({type: actionTypes.RequestOrders, payload: {state}}),
  requestSetting: () => ({type: actionTypes.RequestSetting}),
  requestRemoveError: () => ({type: actionTypes.RequestRemoveError}),
  requestUpdateSetting: (channel_prior: number) => ({
    type: actionTypes.RequestUpdateSetting,
    payload: {channel_prior},
  }),
  fulfillorders: (orders: OrderModel[]) => ({
    type: actionTypes.OrdersLoadedSuccess,
    payload: {orders},
  }),
  loadordersFail: (message: string) => ({type: actionTypes.OrdersLoadedFail, payload: {message}}),
  fillSetting: (channel_prior: number) => ({
    type: actionTypes.RequestSettingSuccess,
    payload: {channel_prior},
  }),
  addOrderRequest: (data: {channel: OrderForm; history: any}) => ({
    type: actionTypes.AddOrderRequest,
    payload: {data},
  }),
  refreshCreate: () => ({type: actionTypes.RefreshCreate}),
  clearErrors: () => ({type: actionTypes.ClearError}),
  addOrderSuccess: (order: OrderModel) => ({type: actionTypes.AddOrderSuccess, payload: {order}}),
  addOrderFail: (message: string) => ({type: actionTypes.AddOrderFail, payload: {message}}),
  requestUpdate: (order: OrderUpdateForm) => ({type: actionTypes.RequestUpdate, payload: {order}}),
  updateSuccess: (order: OrderModel) => ({type: actionTypes.UpdateSuccess, payload: {order}}),
  updateFail: (message: string) => ({type: actionTypes.UpdateFail, payload: {message}}),
  showcurrentOrder: (currentOrder: OrderModel) => ({
    type: actionTypes.ShowcurrentOrder,
    payload: {currentOrder},
  }),
  clearcurrentOrder: () => ({type: actionTypes.ClearSelected}),
  requestGroups: () => ({type: actionTypes.GroupLoadedRequest}),
  fulfillGroups: (groups: Group[]) => ({type: actionTypes.GroupLoadedSuccess, payload: {groups}}),
  addGroupRequest: (groupName: string) => ({
    type: actionTypes.GroupAddRequest,
    payload: {groupName},
  }),
  addGroupSuccess: (group: Group) => ({type: actionTypes.GroupAddSuccess, payload: {group}}),
  deleteGroupRequest: (id: number) => ({type: actionTypes.GroupDeleteRequest, payload: {id}}),
  deleteGroupSuccess: (id: number) => ({type: actionTypes.GroupDeleteSuccess, payload: {id}}),
  selectGroup: (group: Group) => ({type: actionTypes.SelectGroup, payload: {group}}),
  //
  deleteOrderRequest: (channel_id: string) => ({
    type: actionTypes.DeleteOrderRequest,
    payload: {channel_id},
  }),
  deleteOrderSuccess: (channel_id: string) => ({
    type: actionTypes.DeleteOrderSuccess,
    payload: {channel_id},
  }),

  //
  cancelOrderRequest: (channel_id: string) => ({
    type: actionTypes.CancelOrderRequest,
    payload: {channel_id},
  }),
  cancelOrderSuccess: (channel_id: string) => ({
    type: actionTypes.CancelOrderSuccess,
    payload: {channel_id},
  }),
  //
  completeOrderRequest: (channel_id: string) => ({
    type: actionTypes.CompleteOrderRequest,
    payload: {channel_id},
  }),
  completeOrderSuccess: (channel_id: string) => ({
    type: actionTypes.CompleteOrderSuccess,
    payload: {channel_id},
  }),
  //
  restoreOrderRequest: (order_id: number) => ({
    type: actionTypes.RestoreOrderRequest,
    payload: {order_id},
  }),
  restoreOrderSuccess: (order_id: number) => ({
    type: actionTypes.RestoreOrderSuccess,
    payload: {order_id},
  }),
  //
  requestComputers: () => ({type: actionTypes.ListComputerLoadedRequest}),
  fulfillComputerss: (computers: Computer[]) => ({
    type: actionTypes.ListComputerLoadedSuccess,
    payload: {computers},
  }),
  requestReports: (from: string, to: string, type: string) => ({
    type: actionTypes.ReportsRequest,
    payload: {from, to, type},
  }),
  changeFromTo: (from: Date, to: Date) => ({type: actionTypes.ChangeFromTo, payload: {from, to}}),
  fulfillReports: (reports: Report[]) => ({
    type: actionTypes.ReportsLoadSuccess,
    payload: {reports},
  }),
  //
  ConfirmCancelOrderRequest: (channel_id: string,refund:number) => ({
    type: actionTypes.ConfirmCancelOrderRequest,
    payload: {channel_id,refund},
  }),
  ConfirmCancelOrderFail: () => ({
    type: actionTypes.ConfirmCancelOrderFail
  }),

}

export function* saga() {
  yield takeLatest(actionTypes.RequestOrders, function* userRequested(param: any) {
      try {


        const {data: res} = yield getListOrder(param.payload.state.toLowerCase())
        if (res.data) {
          yield put(actions.fulfillorders(res.data))
        } else if (res.likeQuests) {
          yield put(actions.fulfillorders(res.likeQuests))
        }
      } catch (error) {
        console.log('---------Get List orders error---------', error)
      }
  })

  yield takeLatest(actionTypes.RequestSetting, function* userRequested(param: any) {
    const {data: result} = yield getSetting()
    yield put(actions.fillSetting(result.channel_prior))
  })

  //localhost:8080/Fitnees/

  yield takeLatest(actionTypes.RequestUpdateSetting, function* updateUserRequested(param: any) {
    const {data: result} = yield updateSetting(param.payload.channel_prior)
    if (result && result.status === 'success') {
      alert('Đã cập nhật số kênh ưu tiên thành công')
    }
  })

  yield takeLatest(actionTypes.RequestRemoveError, function* updateUserRequested() {
    const {data: result} = yield deleteHistoryError()
    if (result && result.status === 'success') {
      alert('Đã xóa luồng lỗi thành công')
    }
  })

  yield takeLatest(actionTypes.RequestUpdate, function* updateUserRequested(param: any) {
    const {data: result} = yield updateOrder(param.payload.order)
    if (result && result.channel) {
      yield put(actions.updateSuccess(result.channel))
    } else if (result && result.likeQuest) {
      yield put(actions.updateSuccess(result.likeQuest))
    } else {
      yield put(actions.addOrderFail(result.message))
    }
  })
  yield takeLatest(actionTypes.AddOrderRequest, function* addOrderRequest(param: any) {
    const payload = param.payload.data.channel
    const history = param.payload.data.history
    try {
      const {data: result} = yield addOrder({
        order_link: payload.list_channel,
        sub_need : payload.sub_need ,
        priority:payload.priority,
        max_thread: payload.max_thread,
        note: payload.note,
        service_id:1
      })
      if (result && result.channel) {
        alert('Đã thêm kênh thành công!')
        yield history.push('/page/subscribeOrders/dang-chay')
        put(actions.addOrderSuccess(result.channel))
      } else if (result && result.likeQuest) {
        alert('Đã thêm video thành công!')
        yield history.push('/page/likeOrders/dang-chay')
        put(actions.addOrderSuccess(result.likeQuest))
      } else {
        if (result.message) {
          if (result.message.includes('Duplicate entry')) {
            alert('Kênh này đã được thêm trước đây , Vui lòng kiểm tra lại danh sách kênh của bạn')
          } else {
            alert(result.message)
          }
          yield put(actions.addOrderFail(''))
        }
        yield put(actions.addOrderFail(result.message))
      }
    } catch (error) {
      console.log('error', error)
      yield put(actions.addOrderFail(''))
    }
  })
  yield takeLatest(actionTypes.GroupLoadedRequest, function* addOrderRequest(param: any) {
    try {
      return 
      const {data: result} = yield getListGroups()
      if (result && result.groups) {
        yield put(actions.fulfillGroups(result.groups))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })

  yield takeLatest(actionTypes.GroupAddRequest, function* addOrderRequest(param: any) {
    try {
      const payload = param.payload.groupName
      const {data: result} = yield addGroup(payload)
      if (result && result.group) {
        yield put(actions.addGroupSuccess(result.group))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })

  yield takeLatest(actionTypes.GroupDeleteRequest, function* addOrderRequest(param: any) {
    try {
      const payload = param.payload.id
      const {data: result} = yield deleteGroup(payload)
      if (result && result.status === 'success') {
        yield put(actions.deleteGroupSuccess(payload))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })

  
  yield takeLatest(actionTypes.ConfirmCancelOrderRequest, function* DeleteOrderRequest(param: any) {
    try {
      const channel_id = param.payload.channel_id
      const refund = param.payload.refund
      const isBlacklist = param.payload.black_list
      const reasonBl = param.payload.reason
      const {data: result} = yield confirmCancelChannel(channel_id,refund,isBlacklist,reasonBl)
      if (result === 'success') {
        yield put(actions.deleteOrderSuccess(channel_id))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
   yield put(actions.ConfirmCancelOrderFail())
  })


  yield takeLatest(actionTypes.DeleteOrderRequest, function* DeleteOrderRequest(param: any) {
    try {
      const payload = param.payload.channel_id
      const {data: result} = yield deleteChannel(payload)
      if (result === 'success') {
        yield put(actions.deleteOrderSuccess(payload))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })

  yield takeLatest(actionTypes.CancelOrderRequest, function* DeleteOrderRequest(param: any) {
    try {
      const payload = param.payload.channel_id
      const {data: result} = yield cancelChannel(payload)
      if (result === 'success') {
        yield put(actions.cancelOrderSuccess(payload))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })
  yield takeLatest(actionTypes.CompleteOrderRequest, function* CompleteOrderRequest(param: any) {
    try {
      const payload = param.payload.channel_id
      const {data: result} = yield completeChannel(payload)
      if (result === 'success') {
        yield put(actions.completeOrderSuccess(payload))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })
  yield takeLatest(actionTypes.RestoreOrderRequest, function* DeleteOrderRequest(param: any) {
    try {
      const payload = param.payload.order_id
      const {data: result} = yield restoreChannel(payload)
      if (result === 'success') {
        yield put(actions.restoreOrderSuccess(payload))
      } else {
        if(result?.message){
          alert(result?.message)
        }
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })

  yield takeLatest(
    actionTypes.ListComputerLoadedRequest,
    function* ListComputerRequest(param: any) {
     
    }
  )

  yield takeLatest(actionTypes.ReportsRequest, function* requestReports(param: any) {
    try {
      const {data: result} = yield getReports(
        param.payload.from,
        param.payload.to,
        param.payload.type
      )
      if (result && result.reports) {
        yield put(actions.fulfillReports(result.reports))
      } else {
      }
    } catch (error) {
      yield put(actions.addOrderFail(''))
    }
  })
}
