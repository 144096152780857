/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {AuthPage, Logout} from '../modules/auth'
import React, {FC} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'
import {RootState} from 'setup/redux/RootReducer'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const role: string =
    (useSelector<RootState>(({auth}) => auth.user?.role, shallowEqual) as string) || ''
  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : role == 'admin' ? (
          <>
            <PrivateRoutes />
          </>
        ) : (
          <>
            <PublicRoutes />
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
