import HeaderUserbox from '../../layout-components/HeaderUserbox'
import React from 'react'
import clsx from 'clsx'
import {connect} from 'react-redux'
import {setSidebarToggleMobile} from '../../../../setup/redux/ThemeOptions'

const Header = (props) => {
  const {headerShadow, headerBgTransparent, sidebarToggleMobile, setSidebarToggleMobile} = props

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent,
        })}
      >
        <div className='app-header--pane'></div>
        <div className='app-header--pane'>
          <HeaderUserbox />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
