import React, {Component} from 'react'

import HeaderUserbox from '../../../../layout-components/HeaderUserbox'

class NavRight extends Component {
  state = {
    listOpen: false,
  }

  render() {
    return (
      <>
        {/* <ul className='navbar-nav ml-auto'>
          <li>
            <HeaderUserbox />
          </li>
        </ul> */}
      </>
    )
  }
}

export default NavRight
