import './styles.scss'

import {Card, Col, Row, Form, FormGroup, Input, Label} from 'reactstrap'
import {Computer, Report} from 'app/modules/orders/models/Order'
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {PageTitle} from '../../../_metronic/layout/core'
import {RootState} from 'setup'
import {useIntl} from 'react-intl'
import {getSetting, updateSetting} from 'app/modules/orders/redux/OrdersCRUD'
import { Switch } from 'antd'

const SubscribeStatisticWidget: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [max_minute, setMaxMax_minute] = useState(0)
  const [user_threads, setUser_threads] = useState(0)
  const [user_threads_4000, setUser_threads_4000] = useState(0)
  const [user_threads_5000, setUser_threads_5000] = useState(0)
  const [max_orders, setMax_orders] = useState(0)
  const [price_every_10_subs, setPrice_every_10_subs] = useState(0.0)
  const [bonus_over_500, setBonus_over_500] = useState(0)
  const [bonus_under_500, setBonus_under_500] = useState(0)
  //
  const [sub_needs, setSub_needs] = useState(0)
  const [time_wait, setTime_wait] = useState(0)
  const [keep_rate, setKeep_rate] = useState(0)
  const [min_time, setMin_time] = useState(0)
  const [max_time, setMax_time] = useState(0)
  const [limit_day, setLimit_day] = useState(0)
  const [view_verified, setView_verified] = useState(0)
  const [min_publish_date, setMin_publish_date] = useState(0)
  const [isBlockVideo, setIsBlockVideo] = useState(false)

  const setBlockVideo = () => {
    setIsBlockVideo(!isBlockVideo)
  };

  

  const fetchSetting = async () => {
    const {data: result} = await getSetting()
    console.log("========setting=====",result)
    if (result && result.data) {
      setMaxMax_minute(result.data.max_minute)
      setUser_threads(result.data.user_threads)
      setUser_threads_4000(result.data.user_threads_4000)
      setUser_threads_5000(result.data.user_threads_5000)
      setMax_orders(result.data.max_orders)
      setPrice_every_10_subs(result.data.price_every_10_subs)
      setBonus_over_500(result.data.bonus_over_500)
      setBonus_under_500(result.data.bonus_under_500)
      setSub_needs(result.data.sub_needs)
      setTime_wait(result.data.time_wait)
      setKeep_rate(result.data.keep_rate)
      setView_verified(result.data.view_verified)
      setMin_publish_date(result.data.min_publish_date)
      setIsBlockVideo(result.data.block_video)
      setMin_time(result.data.min_time)
      setMax_time(result.data.max_time)
      setLimit_day(result.data.limit_day)

    }
  }

  useEffect(() => {
    fetchSetting()
  }, [])

  const clickUpdateSetting = async () => {
    if (window.confirm('Bạn có chắc chắn muốn cập nhật setting không') == true) {
      const setting = {
        max_minute,
        user_threads,
        max_orders,
        bonus_over_500,
        bonus_under_500,
        price_every_10_subs,
        sub_needs,
        time_wait,
        user_threads_4000,
        user_threads_5000,
        keep_rate,
        view_verified,
        min_publish_date,
        isBlockVideo,
        min_time,
        max_time,
        limit_day
      }
      const {data: result} = await updateSetting(setting)
      if (result && result.data) {
        alert('Cập nhật cấu hình thành công')
      }
    }
  }

  return (
    <Card className='card-box p-settingCard text-center'>
      <div className='card-header-alt'>
        <div className='font-weight-bold d-flex align-items-center justify-center p-settingCard__heading'>
          <i className='feather icon-settings mr-3'></i>
          <h3>{intl.formatMessage({id: 'DASHBOARD.SUBSCRIBE.SETTING'})}</h3>
        </div>
      </div>
      <Form className='text-left p-4 pt-0'>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label '>
                Số phút tối đa lấy video
              </Label>
              <Input
                id='max_minute'
                name='max_minute'
                className='form-control form-control-solid'
                placeholder={'Số phút lấy tối đa của video trong kênh'}
                value={max_minute}
                type={'number'}
                onChange={(e) => setMaxMax_minute(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Luồng max đơn User
              </Label>
              <Input
                id='user_thread'
                name='user_thread'
                className='form-control form-control-solid'
                placeholder={'Số luồng max mỗi đơn user'}
                value={user_threads}
                type={'number'}
                onChange={(e) => setUser_threads(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label '>
               Luồng max đơn User lớn hơn 4000 
              </Label>
              <Input
                id='user_threads_4000'
                name='user_threads_4000'
                className='form-control form-control-solid'
                placeholder={'Số phút lấy tối đa của video trong kênh'}
                value={user_threads_4000}
                type={'number'}
                onChange={(e) => setUser_threads_4000(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
              Luồng max đơn User lớn hơn 5000 
              </Label>
              <Input
                id='user_thread_5000'
                name='user_thread_5000'
                className='form-control form-control-solid'
                placeholder={'Số luồng max mỗi đơn user'}
                value={user_threads_5000}
                type={'number'}
                onChange={(e) => setUser_threads_5000(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Số đơn sub max hệ thống
              </Label>
              <Input
                id='user_thread'
                name='user_thread'
                className='form-control form-control-solid'
                placeholder={'Số đơn max hệ thống'}
                value={max_orders}
                type={'number'}
                onChange={(e) => setMax_orders(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Đơn giá mỗi 10 subcribes
              </Label>
              <Input
                id='price_rate'
                name='price_rate'
                className='form-control form-control-solid'
                placeholder={'Đơn giá trên 10 subcribes'}
                value={price_every_10_subs}
                type={'number'}
                onChange={(e) => setPrice_every_10_subs(parseFloat(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Số % sub cộng thêm cho đơn trên 500 subscribes
              </Label>
              <Input
                id='bonus_over_500'
                name='bonus_over_500'
                className='form-control form-control-solid'
                placeholder={'Số sub cộng thêm cho đơn trên 500 likes'}
                value={bonus_over_500}
                type={'number'}
                onChange={(e) => setBonus_over_500(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Số % sub cộng thêm cho đơn dưới 500 subscribes
              </Label>
              <Input
                id='bonus_under_500'
                name='bonus_under_500'
                className='form-control form-control-solid'
                placeholder={'Số sub cộng thêm cho đơn dưới 500 subscribes'}
                value={bonus_under_500}
                type={'number'}
                onChange={(e) => setBonus_under_500(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Số sub chạy test
              </Label>
              <Input
                id='sub_wait'
                name='sub_wait'
                className='form-control form-control-solid'
                placeholder={'Số sub cộng thêm cho đơn trên 500 subscribes'}
                value={sub_needs}
                type={'number'}
                onChange={(e) => setSub_needs(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Thời gian chờ verify kênh (phút)
              </Label>
              <Input
                id='time_wait'
                name='time_wait'
                className='form-control form-control-solid'
                placeholder={'Số sub cộng thêm cho đơn dưới 500 subscribes'}
                value={time_wait}
                type={'number'}
                onChange={(e) => setTime_wait(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>


        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
               Hiệu suất kiểm tra luồng
              </Label>
              <Input
                id='keep_rate'
                name='keep_rate'
                className='form-control form-control-solid'
                placeholder={'Số luồng like tối đa user'}
                value={keep_rate}
                type={'number'}
                onChange={(e) => setKeep_rate(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
               thời gian upload video tối thiểu (Ngày)
              </Label>
              <Input
                id='min_publish_date'
                name='min_publish_date'
                className='form-control form-control-solid'
                placeholder={'Số luồng like tối đa user'}
                value={min_publish_date}
                type={'number'}
                onChange={(e) => setMin_publish_date(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Thời gian xem tối thiểu
              </Label>
              <Input
                id='min_time'
                name='min_time'
                className='form-control form-control-solid'
                placeholder={'Số luồng like tối đa user'}
                value={min_time}
                type={'number'}
                onChange={(e) => setMin_time(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
               Thời gian xem tối đa
              </Label>
              <Input
                id='max_time'
                name='max_time'
                className='form-control form-control-solid'
                placeholder={'Số luồng like tối đa user'}
                value={max_time}
                type={'number'}
                onChange={(e) => setMax_time(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <FormGroup>
              <Label for='exampleEmail' className='required form-label'>
                Số view đơn cũ đã chạy bỏ qua trạng thái chờ
              </Label>
              <Input
                id='view_verified'
                name='view_verified'
                className='form-control form-control-solid'
                placeholder={'Số luồng like tối đa user'}
                value={view_verified}
                type={'number'}
                onChange={(e) => setView_verified(parseInt(e.target.value))}
              />
            </FormGroup>
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className='col-2'>
                <p>Block Video</p>
                <Switch defaultChecked onChange={setBlockVideo} checked={isBlockVideo}/>
              </div>
              <div className="col-10">
                <FormGroup>
                  <Label for='exampleEmail' className='required form-label'>
                    Max sub ngày
                  </Label>
                  <Input
                    id='limit_day'
                    name='limit_day'
                    className='form-control form-control-solid'
                    placeholder={'Số luồng like tối đa user'}
                    value={limit_day}
                    type={'number'}
                    onChange={(e) => setLimit_day(parseInt(e.target.value))}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'>
          <div className='divider mb-4 mt-3' />
          <button type='button' onClick={clickUpdateSetting} className='btn btn-primary mb-4'>
            {'Cập nhật cấu hình'}
          </button>
        </div>
      </Form>
    </Card>
  )
}

const SettingWrapper: FC = () => {
  const intl = useIntl()
  const role: string =
    (useSelector<RootState>(({auth}) => auth.user?.role, shallowEqual) as string) || ''

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Cài đặt'}</PageTitle>
      {role === 'admin' ? (
        <Row>
          <Col lg='12'>
            <SubscribeStatisticWidget />
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export {SettingWrapper}
