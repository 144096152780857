import { Affix, Divider, Form, Input, Select, Space, List, Typography, Skeleton, Button } from 'antd'
import {
  TabContent, TabPane, Nav,
  NavItem, NavLink, Badge, Col, Row
} from 'reactstrap'
import { Group, OrderModel } from './models/Order'
import MultipleEditModal, { MultipleEditModalHandle } from './modals/EditModal/multiple'
import { OrderForm, OrderPost } from './models/Order'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { AccountModel } from 'app/modules/accounts/models/Account'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from 'setup'
import { actions } from './redux/OrdersRedux'
import { debounce } from 'lodash'
import moment from 'moment'
import { postWithoutTokenFunciton } from 'utils/ApiHelper'
import { useHistory } from 'react-router-dom'
import { format } from 'utils/convert'
import { getListAccount } from '../accounts/AccountCRUD'
import { cancelChannel, completeChannel, confirmCancelChannel, getListOrder, getListOrderUser } from './redux/OrdersCRUD'
import classnames from 'classnames';
type Props = {
  className: string
  state: string
  apiAlias: string
}

const UserOrderList: React.FC<Props> = ({ apiAlias, className, state }) => {
  const editMultipleModal = useRef<MultipleEditModalHandle>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState('')
  const role: string =
    (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''
  const groups: Group[] =
    (useSelector<RootState>(({ orders }) => orders.groups, shallowEqual) as Group[]) || []
  const [currentGroup, setCurrentGroup] = useState<Group>()
  const [orders, setOrders] = useState<OrderModel[]>([])
  const [ordersPending, setOrderPending] = useState<OrderModel[]>([])
  const [ordersRunning, setOrderRunning] = useState<OrderModel[]>([])
  const [ordersHistory, setOrdersHistory] = useState<OrderModel[]>([])
  const [ordersCancel, setOrdersCancel] = useState<OrderModel[]>([])
  const [currentUser, setCurrentUser] = useState<AccountModel>()
  const [priority, setPriority] = useState<number>(-1)
  const [currentChannelID, setCurrentChannelID] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [filter, setFilter] = useState(-1);

  const [currentCancelChannel, setCurrentCancelChannel] = useState(-1)


  const fetchOrderTask = async () => {
    setLoading(true)
    const rest = await getListOrderUser()
    if (rest?.data?.data) {

      let _ordersPending:OrderModel[]=[];
      let _ordersRunning:OrderModel[]=[];
      let _ordersHistory:OrderModel[]=[];
      let _ordersCancel:OrderModel[]=[];
      rest?.data?.data?.forEach((order: OrderModel) => {
        if(!order.end_time && order.is_pending == 0){
          _ordersRunning.push(order)
        }
        if(!order.end_time && order.is_pending == 1){
          _ordersPending.push(order)
        }
        if (order.end_time && order.cancel == 0) {
          _ordersHistory.push(order)
        }
        if (order.end_time && order.cancel == 1) {
          _ordersCancel.push(order)
        }
      })
      setOrderPending(_ordersPending);
      setOrderRunning(_ordersRunning);
      setOrdersHistory(_ordersHistory);
      setOrdersCancel(_ordersCancel);

      setOrders(rest?.data.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchOrderTask()
  }, [])

  useEffect(() => {
    if (!loading) {
      setCurrentCancelChannel(-1);
    }

  }, [loading])


  const clickCancelHandler = async (item: OrderModel) => {
    if (window.confirm('bạn có chắc chắn muốn hủy đơn này') === true) {
      const res: any = await cancelChannel(item.order_id)
      if (res?.data?.success === true) {
        setOrders(orders.filter(_item => {
          if (_item.order_id === item.order_id) {
            return false
          }
          return true
        }))
      }
    }
  }

  const addGroup = () => {
    if (groupName.length === 0) {
      alert('vui lòng điền tên nhóm')
      return
    }
    dispatch(actions.addGroupRequest(groupName))
    setGroupName('')
  }


  const searchByChannelID = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => {
      setCurrentChannelID(e.target.value)
    }, 1000)()
  }

  const getData=()=>{
    if(filter===-1){
      setOrders(ordersRunning)
    }else if (filter===0){
      setOrders(ordersRunning)
    }else if (filter===1){
      setOrders(ordersPending)
    }else if (filter===2){
      setOrders(ordersHistory)
    }else{
      setOrders(ordersCancel)
    }
  }


  useEffect(() => {
    getData()

  }, [filter])

 
  orders.sort((a: OrderModel, b: OrderModel) => {
    return b.order_id - a.order_id
  });


  const loadMore = async () => {

  }


  const getAvatar = (item: OrderModel) => {
    if (item.end_time) {
      if (item.cancel == 1) {
        return <ExclamationCircleOutlined style={{ width: '30px', fontSize: '16px', color: '#e74c3c' }} />
      } else {
        return <ClockCircleOutlined style={{ width: '30px', fontSize: '16px', color: '#2ecc71' }} />
      }
    } else {
      if (item.is_pending == 1) {
        return <QuestionCircleOutlined width={30} style={{ width: '30px', fontSize: '16px', color: '#f1c40f' }} />
      } else {
        return <CheckCircleOutlined width={30} style={{ width: '30px', fontSize: '16px', color: '#3498db' }} />
      }
    }
  }

  const getTitle = (item: OrderModel) => {
    if (item.end_time) {
      if (item.cancel == 1) {
        return <span style={{ color: '#e74c3c', fontWeight: 'bold' }}>{"Cancel : " + item.cancel_reason}</span>
      } else {
        return <a href={"https://www.youtube.com/channel/" + item.channel_id} style={{ color: '#2ecc71', fontWeight: 'bold' }}>{"Hoàn thành | " + item.channel_id}</a>
      }
    } else {
      if (item.is_pending == 1) {
        return <a href={item.order_link} style={{ color: '#f1c40f', fontWeight: 'bold' }}>{"Pending"}</a>
      } else {
        return <a href={"https://www.youtube.com/channel/" + item.channel_id} style={{ color: '#3498db', fontWeight: 'bold' }}>{"Đang chạy | " + item.channel_id}</a>
      }
    }
  }
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  return (
    <div className='c-order c-customer'>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className=' justify-content-between'>
            <span className='text-danger ml-2 fw-bold fs-7 mb-20'>
              ({format(orders.length, 0, 3)} {' Orders '}){' '}
            </span>
            <div className='card-toolbar w-100'>
              <div className='d-flex w-100'>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: filter === -1
                      })}
                      onClick={() => { setFilter(-1); }}
                    >
                      Tất cả
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: filter === 0
                      })}
                      onClick={() => { setFilter(0); }}
                    >
                      Đang chạy  {` (${format(ordersRunning.length, 0, 3)})`}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: filter === 1
                      })}
                      onClick={() => { setFilter(1); }}
                    >
                      Đang duyệt  {` (${format(ordersPending.length, 0, 3)})`}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: filter === 2
                      })}
                      onClick={() => { setFilter(2); }}
                    >
                      Đã hoàn thành {` (${format(ordersHistory.length, 0, 3)})`}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: filter === 3
                      })}
                      onClick={() => { setFilter(3); }}
                    >
                      Hủy {` (${format(ordersCancel.length, 0, 3)})`}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div >
            {
              <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={orders}
                renderItem={(item: OrderModel) => (
                  <List.Item
                    actions={[<Button onClick={() => clickCancelHandler(item)} key="list-loadmore-edit">Cancel</Button>]}
                  >
                    <List.Item.Meta
                      avatar={getAvatar(item)}
                      title={getTitle(item)}
                      description={
                        <div>
                          {
                            item.end_time ? <span>{moment.unix(item.first_time).format("DD/MM HH:mm")} ~ {moment.unix(item.end_time).format("DD/MM HH:mm")}</span> :
                              <span>{' Thời gian order : ' + moment.unix(item.first_time).format("DD/MM HH:mm")}</span>
                          }

                          <Row>
                            <Col sm={2}>
                              <span>{"Quantity : "}</span><span style={{ fontSize: '14px', color: 'black' }}>{format(Math.abs(item.first_sub_order) || 0, 0, 3)}</span>
                            </Col>
                            <Col sm={2}>
                              <span>
                                {"Init : "}
                              </span>
                              <span style={{ fontSize: '14px', color: 'black' }}>{format(Math.abs(item.first_sub_start) || 0, 0, 3)}</span>
                            </Col>
                            <Col sm={2}>
                              <span>
                                {"Current sub : "}
                              </span>
                              <span style={{ fontSize: '14px', color: 'black' }}>{format(Math.abs(item.current_sub) || 0, 0, 3)}</span>
                            </Col>
                            <Col sm={2}>
                              <span>
                                {"Remains : "}
                              </span>
                              <span style={{ fontSize: '14px', color: 'black' }}>{format((item.first_sub_order - (item.current_sub - item.first_sub_start)) || 0, 0, 3)}</span>
                            </Col>
                            <Col sm={4}>
                              <span>
                                {"Link Order : "}
                              </span>
                              <span style={{ fontSize: '12px', color: 'grey' }}>{item.order_link}</span>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                    <Row>
                      <Col>
                        <span>
                          {item.order_id}
                        </span>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export { UserOrderList }
