import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import EditModal from './modals/EditModal'
import { OrderList } from './OrderList'
import { OrderModel } from './models/Order'
import { RootState } from 'setup'
import { actions } from './redux/OrdersRedux'
import { HistoryList } from './HistoryList'
import { UserOrderList } from './UserOrderList'
import { RestoreHistoryList } from './RestoreHistoryList'

const WidgetsPage: React.FC = (props: any) => {

  const dispatch = useDispatch()
  const channel_prior: number = useSelector<RootState>(({ orders }) => orders.channel_prior, shallowEqual) as number || 0
  const [channelPrior, setChannelPrior] = useState(channel_prior)
  const currentOrder: OrderModel = useSelector<RootState>(({ orders }) => orders.currentOrder, shallowEqual) as OrderModel || undefined
  const role: string =
  (useSelector<RootState>(({ auth }) => auth.user?.role, shallowEqual) as string) || ''

  useEffect(() => {
    window.apiAlias = props.apiAlias;
    dispatch(actions.requestSetting())
    dispatch(actions.requestGroups())
  }, [])

  useEffect(() => {
    console.log("load page "+props.match.params.state)
    if(props.match.params.state!=="lich-su"){
      dispatch(actions.requestOrders(props.match.params.state))
    }
  }, [props.match.params.state])


  useEffect(() => {
    setChannelPrior(channel_prior)
  }, [channel_prior])


  const content =()=>{


    if(role!=='admin'){
      return <UserOrderList key={''} apiAlias={props.apiAlias} state={''} className='card-xxl-stretch mb-5 mb-xl-12 aaaaaaaa' />;
    }


    if(props.match.params.state?.includes("history")){
      if(props.match.params.state=="restore_history"){
        return <RestoreHistoryList apiAlias={props.apiAlias} state={props.match.params.state} className='card-xxl-stretch mb-5 mb-xl-12' />
      }else{
        return <HistoryList apiAlias={props.apiAlias} state={props.match.params.state} className='card-xxl-stretch mb-5 mb-xl-12' />
      }
    }else{
      if(role==='admin'){
        return <OrderList key={props.match.params.state} apiAlias={props.apiAlias} state={props.match.params.state} className='card-xxl-stretch mb-5 mb-xl-12' />
      }else{
        return <UserOrderList key={props.match.params.state} apiAlias={props.apiAlias} state={props.match.params.state} className='card-xxl-stretch mb-5 mb-xl-12' />;
      }
    }
  }


  return (
    <>
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xl-12'>
          {
            content()
          }
        </div>
      </div>
      {
        currentOrder && <EditModal key={currentOrder?.channel_id} item={currentOrder} />
      }
    </>
  )
}

export default WidgetsPage
