import * as actionTypes from 'setup/redux/DattaThemeOptions'

import React, { Component } from 'react'

import { FormattedMessage } from 'react-intl'
import NavContent from './NavContent'
import NavLogo from './NavLogo'
import OutsideClick from './OutsideClick'
import { connect } from 'react-redux'
import windowSize from 'react-window-size'
import { withRouter } from 'react-router-dom'

class Navigation extends Component {
  resize = () => {
    const contentWidth = document.getElementById('root').clientWidth

    if (this.props.layout === 'horizontal' && contentWidth < 992) {
      this.props.onChangeLayout('vertical')
    }
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render() {


    const navigation = {
      items: [
        ...(this.props.role === 'admin'
          ? [
            {
              id: 'feature',
              title: <FormattedMessage id='MENU.FEATURES' />,
              type: 'group',
              icon: 'icon-navigation',
              children: [
                {
                  id: 'dashboard',
                  title: <FormattedMessage id='MENU.DASHBOARD' />,
                  type: 'item',
                  url: '/dashboard',
                  icon: 'feather icon-pie-chart',
                },
                {
                  id: 'statistic',
                  title: 'Thống kê',
                  type: 'item',
                  url: '/statistic',
                  icon: 'feather icon-activity',
                },
                {
                  id: 'setting',
                  title: <FormattedMessage id='MENU.SETTING' />,
                  type: 'item',
                  url: '/setting',
                  icon: 'feather icon-settings',
                },
                {
                  id: 'services',
                  title: "Dịch vụ",
                  type: 'item',
                  url: '/services',
                  icon: 'feather icon-sliders',
                },
                {
                  id: 'user',
                  title: <FormattedMessage id='MENU.USERS' />,
                  type: 'item',
                  url: '/page/account',
                  icon: 'feather icon-users',
                },
                {
                  id: 'user',
                  title: 'Tạo đơn',
                  type: 'item',
                  url: '/page/subscribeOrders/create',
                  icon: 'feather icon-user-plus',
                },
              ],
            },
          ]
          : []),

        ...(this.props.role !== 'admin'
          ? [
            {
              id: 'feature',
              title: "Orders",
              type: 'group',
              icon: 'icon-navigation',
              children: [
                {
                  id: 'user',
                  title: 'Tạo Đơn',
                  type: 'item',
                  url: '/orders/create',
                  icon: 'feather icon-user-plus',
                },
                {
                  id: 'cancel',
                  title: "Danh sách đơn",
                  type: 'item',
                  url: '/orders',
                  icon: 'feather icon-zap',
                },
              ],
            },
          ]
          : [
            {
              id: 'ORDER',
              title: "Danh sách đơn",
              type: 'group',
              icon: 'icon-activity',
              children: [
                {
                  id: 'pending',
                  title: "Đơn chờ duyệt",
                  type: 'item',
                  url: '/page/subscribeOrders/pending',
                  icon: 'feather icon-zap',
                },
                {
                  id: 'processing',
                  title: "Đang chạy",
                  type: 'item',
                  url: '/page/subscribeOrders/running',
                  icon: 'feather icon-zap',
                },
                {
                  id: 'refill',
                  title: "Đang bảo hành",
                  type: 'item',
                  url: '/page/subscribeOrders/refill',
                  icon: 'feather icon-zap',
                },
                {
                  id: 'cancel',
                  title: "Chờ hủy",
                  type: 'item',
                  url: '/page/subscribeOrders/cancel',
                  icon: 'feather icon-zap',
                },
              ],
            }
          ]),
        {
          ...this.props.role === "admin" ? {
            id: 'ORDER',
            title: "Lịch sử",
            type: 'group',
            icon: 'icon-activity',
            children: [
              {
                id: 'history-complete',
                title: "Lịch sử Hoàn Thành",
                type: 'item',
                url: '/page/subscribeOrders/complete_history',
                icon: 'feather icon-list',
              },
              {
                id: 'history-cancel',
                title: "Lịch sử hủy",
                type: 'item',
                url: '/page/subscribeOrders/cancel_history',
                icon: 'feather icon-list',
              },
              {
                id: 'restore_history',
                title: "Quét Bảo hành",
                type: 'item',
                url: '/page/subscribeOrders/restore_history',
                icon: 'feather icon-list',
              }
            ],
          } : {}
        }



      ],
    }






    let navClass = ['pcoded-navbar']

    if (
      this.props.preLayout !== null &&
      this.props.preLayout !== '' &&
      this.props.preLayout !== 'layout-6' &&
      this.props.preLayout !== 'layout-8'
    ) {
      navClass = [...navClass, this.props.preLayout]
    } else {
      navClass = [
        ...navClass,
        this.props.layoutType,
        this.props.navBackColor,
        this.props.navBrandColor,
        'drp-icon-' + this.props.navDropdownIcon,
        'menu-item-icon-' + this.props.navListIcon,
        this.props.navActiveListColor,
        this.props.navListTitleColor,
      ]

      if (this.props.layout === 'horizontal') {
        navClass = [...navClass, 'theme-horizontal']
      }

      if (this.props.navBackImage) {
        navClass = [...navClass, this.props.navBackImage]
      }

      if (this.props.navIconColor) {
        navClass = [...navClass, 'icon-colored']
      }

      if (!this.props.navFixedLayout) {
        navClass = [...navClass, 'menupos-static']
      }

      if (this.props.navListTitleHide) {
        navClass = [...navClass, 'caption-hide']
      }
    }

    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      navClass = [...navClass, 'mob-open z-index-9999']
    } else if (this.props.collapseMenu) {
      navClass = [...navClass, 'navbar-collapsed']
    }

    if (this.props.preLayout === 'layout-6') {
      document.body.classList.add('layout-6')
      document.body.style.backgroundImage = this.props.layout6Background
      document.body.style.backgroundSize = this.props.layout6BackSize
    }

    if (this.props.preLayout === 'layout-8') {
      document.body.classList.add('layout-8')
    }

    if (this.props.layoutType === 'dark') {
      document.body.classList.add('datta-dark')
    } else {
      document.body.classList.remove('datta-dark')
    }

    if (this.props.rtlLayout) {
      document.body.classList.add('datta-rtl')
    } else {
      document.body.classList.remove('datta-rtl')
    }

    if (this.props.boxLayout) {
      document.body.classList.add('container')
      document.body.classList.add('box-layout')
    } else {
      document.body.classList.remove('container')
      document.body.classList.remove('box-layout')
    }

    let navContent = (
      <div className='navbar-wrapper relative'>
        <NavContent navigation={navigation.items} />
        <NavLogo
          collapseMenu={this.props.collapseMenu}
          windowWidth={this.props.windowWidth}
          onToggleNavigation={this.props.onToggleNavigation}
        />
      </div>
    )
    if (this.props.windowWidth < 992) {
      navContent = (
        <OutsideClick>
          <div className='navbar-wrapper'>

            <NavContent navigation={navigation.items} />
            <NavLogo
              collapseMenu={this.props.collapseMenu}
              windowWidth={this.props.windowWidth}
              onToggleNavigation={this.props.onToggleNavigation}
            />
          </div>
        </OutsideClick>
      )
    }

    return (
      <>
        <nav className={navClass.join(' ')}>{navContent}</nav>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const role = state.auth && state.auth.user ? state.auth.user.role : ''
  return {
    role,
    layout: state.DattaThemeOptions.layout,
    preLayout: state.DattaThemeOptions.preLayout,
    collapseMenu: state.DattaThemeOptions.collapseMenu,
    layoutType: state.DattaThemeOptions.layoutType,
    navBackColor: state.DattaThemeOptions.navBackColor,
    navBackImage: state.DattaThemeOptions.navBackImage,
    navIconColor: state.DattaThemeOptions.navIconColor,
    navBrandColor: state.DattaThemeOptions.navBrandColor,
    layout6Background: state.DattaThemeOptions.layout6Background,
    layout6BackSize: state.DattaThemeOptions.layout6BackSize,
    rtlLayout: state.DattaThemeOptions.rtlLayout,
    navFixedLayout: state.DattaThemeOptions.navFixedLayout,
    boxLayout: state.DattaThemeOptions.boxLayout,
    navDropdownIcon: state.DattaThemeOptions.navDropdownIcon,
    navListIcon: state.DattaThemeOptions.navListIcon,
    navActiveListColor: state.DattaThemeOptions.navActiveListColor,
    navListTitleColor: state.DattaThemeOptions.navListTitleColor,
    navListTitleHide: state.DattaThemeOptions.navListTitleHide,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    onChangeLayout: (layout) => dispatch({ type: actionTypes.CHANGE_LAYOUT, layout: layout }),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(Navigation)))
