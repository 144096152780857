import { OrderModel, OrderPost } from '../models/Order'
import { getFunciton, postWithoutTokenFunciton } from 'utils/ApiHelper'

import axios from 'axios'

export async function getListOrder(state: String) {
  const res: any = await getFunciton(`order/list?state=` + state.toLowerCase())
  return res
}

export async function getListOrderUser() {
  const res: any = await getFunciton('order/user/list')
  return res
}

export async function listHistory() {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await getFunciton(`${apiAlias}/listHistory`)
  return res
}
export async function listHistorySubsribe(from: string, to: string, user_id: number,cancel:number) {

  const res: any = await getFunciton(`order/history/list?fromDay=${from}&toDay=${to}&user_id=${user_id}&cancel=${cancel}`)
  return res

}
export async function listHistoryByChannelID(channel_id: string) {
  const res: any = await getFunciton(`order/history/list?channel_id=${channel_id}`)
  return res
}

export async function deleteHistoryError() {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await getFunciton(`${apiAlias}/deleteHistoryError`)
  return res
}

export async function getMyPoints() {
  const res: any = await getFunciton('point/list/me')
  return res
}
export async function getSetting() {
  const res: any = await getFunciton(`admin/setting`)
  return res
}
export async function getListGroups() {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await getFunciton(`/subscribe/getGroups`)
  return res
}

export async function updateSetting(setting: any) {
  const res: any = await postWithoutTokenFunciton('admin/setting', setting)
  return res
}

export async function updateOrder(order: OrderModel) {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await postWithoutTokenFunciton(`${apiAlias}/update`, order)
  return res
}

export async function addOrder(order: OrderPost) {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await postWithoutTokenFunciton(`order/add`, order)
  return res
}



export async function addGroup(groupName: string) {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await postWithoutTokenFunciton(`/${apiAlias}/insertGroup`, {
    name: groupName,
  })
  return res
}

export async function deleteGroup(id: number) {
  const apiAlias = window.apiAlias || 'subscribe'
  const res: any = await getFunciton(`/${apiAlias}/deleteGroup?id=` + id)
  return res
}

export async function deleteChannel(channel_id: string) {
  const apiAlias = window.apiAlias || 'subscribe'
  if (apiAlias === 'subscribe') {
    return await getFunciton(`/${apiAlias}/deleteChannel?channel_id=` + channel_id)
  } else if (apiAlias === 'like') {
    return await getFunciton(`/${apiAlias}/deleteLikeQuest?video_id=` + channel_id)
  }
}

export async function confirmCancelChannel(order_id: number, refund: boolean, isBlacklist: boolean, reasonBl: string) {
  return await getFunciton(`order/confirm/` + order_id + "?refund=" + refund +"&black_list="+ isBlacklist +'&reason='+reasonBl )
}



export async function cancelChannel(order_id: number) {
  return await getFunciton(`order/cancel/`+order_id)
}
export async function completeChannel(order_id: number) {
  return await getFunciton(`order/delete/`+order_id)
}
export async function restoreChannel(order_id: number) {
  return await getFunciton(`order/restore/` + order_id)
}
export async function refundOrder(order_id: number) {
  return await getFunciton(`order/refund/` + order_id)
}
export async function refundFullOrder(order_id: number) {
  return await getFunciton(`order/refundFull/` + order_id)
}
export async function listComputer() {
  return await getFunciton('computer/list')
}
export async function listTasks() {
  return await getFunciton('admin/reportCountSuccess')
}
export async function listError() {
  return await getFunciton('admin/getCountError')
}
export async function getReports(from: string, to: string, type: string) {
  return await getFunciton('/admin/report?from=' + from + '&to=' + to)
}

export async function getSubStatis(from: string, to: string, type: string) {
  return await getFunciton('/sub/statistic?from=' + from + '&to=' + to)
}

export async function getListVideo(channel_id: string) {
  return await getFunciton('/report/getChannelData/'+ channel_id)
}

export async function getSubOfVideo(order_id: any) {
  return await postWithoutTokenFunciton('order/statistic', order_id)
}

export async function exportCSV(computer:any) {
  return await getFunciton('/export?computer=' + computer)
}

export async function updateStatus(actions: any) {
  return await postWithoutTokenFunciton('computer/action', actions)
}

export async function listStatisRp() {
  return await getFunciton('/report/statistic')
}

export async function deleteComputer(computer_name: any) {
  return await postWithoutTokenFunciton('computer/delete', computer_name)
}

export async function fillTask() {
  return await getFunciton('/fixTask')
}

export async function getCountProfitp() {
  return await getFunciton('sub/countProfit')
}

export async function reloadVideo(channel_id: any) {
  return await postWithoutTokenFunciton('order/reloadVideo', channel_id)
}

export async function cancelRefundPending(order_id: any) {
  return await postWithoutTokenFunciton('order/cancelRefundPending', order_id)
}

export async function updateInfoComputer(data: any) {
  return await postWithoutTokenFunciton('computer/updateInfo', data)
}

