import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
    numberRow: number
}

const LoadingRow: React.FC<Props> = ({ numberRow }) => {

    const renderR = () => {
        const arr=[]
        for (let index = 0; index < numberRow; index++) {
            arr.push('aaaa')
        }
        return  arr.map(item=>{
            return <tr>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
        </tr>
        })
       
    }

    return (
        <>
          {renderR()}
        </>
    )
}

export default LoadingRow