import './styles.scss'

import { Button, Card, Col, Container, FormGroup, Input, Nav, NavItem, Row } from 'reactstrap'
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { NavLink as NavLinkStrap } from 'reactstrap'
import { Registration } from './components/Registration'
import hero from '../../assets/images/hero-bg/hero-1.jpg'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

export function AuthPage() {
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
    <div className='p-page'>
      <div className="css-w0ozu7">

      </div>
      <div className="css-k538ac">
      <Container className='container-auth '>
            <Switch>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/registration' component={Registration} />
              <Route path='/auth/forgot-password' component={ForgotPassword} />
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </Container>
      </div>
    
    </div>
      
    </>
  )
}
