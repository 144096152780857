
import * as auth from '../../app/modules/auth'
import * as orders from '../../app/modules/orders'

import DattaThemeOptions from './DattaThemeOptions'
import ThemeOptions from './ThemeOptions'
import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  orders: orders.reducer,
  ThemeOptions,
  DattaThemeOptions,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), orders.saga()])
}
