import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AdminLayout from 'app/layout/layout-datta-able/AdminLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { FallbackView } from '../../_metronic/partials'
import { LeftSidebar } from '../layout/layout-blueprints'
import { MeWrapper } from '../pages/me/MeWrapper'
import { useLocation } from 'react-router-dom'

const CreateOrderPage = lazy(() => import('../modules/orders/CreateOrder'))

export function PublicRoutes() {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/orders/OrdersPage'))
  const OrderDashboardPage = lazy(() => import('../modules/orders/OrdersDashboard'))
  const OrderList = lazy(() => import('../modules/orders/Orders'))
  const location = useLocation()

  return (
    <AdminLayout>
      <Suspense fallback={<FallbackView />}>
        <Switch location={location} key={location.pathname}>
          {/* <Route path='/crafted/orders/dashboard' component={OrderDashboardPage} /> */}
          <Route path='/orders/create' component={(props: any) => <CreateOrderPage {...props} apiAlias="subscribe" />} />
          <Route path='/me' component={MeWrapper} />
          <Route path='/orders' component={(props: any) => <WidgetsPage {...props} apiAlias="subscribe" />} />
          <Redirect from='/auth' to='/orders' />
          <Redirect exact from='/' to='/orders' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </AdminLayout>
  )
}
